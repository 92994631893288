import React, { Component } from 'react';
import './../../Modal.css';
import {
  updateSelectedFlag,
} from '../../../../actions/adminActions/usersActions';
import { getContrastColor } from '../../../../apis/colors';
import { connect } from 'react-redux';

class NoteFlagsModal extends Component {
  renderFlags() {
    return (
      <>
        {this.props.noteFlags
          .map((flag) => (
            <tr key={this.props.noteFlags.indexOf(flag)}>
              <td className='comment-block' style={{ width: '45%' }}>
                <div className='comment-info flag-style' style={{ backgroundColor: `${ flag.color }`, color: getContrastColor(flag.color) }}>
                  Flag: {flag.name}
                </div>
                <div className='comment-status'>
                  {flag.description}
                </div>
              </td>
              <td className="text-center" style={{ width: '9%' }}>
                <div
                  data-target="#editNoteFlag"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedFlag(flag.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center" style={{ width: '9%' }}>
                {flag.hidden ? null : <div
                  data-target="#deleteNoteFlag"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedFlag(flag.id);
                  }}
                >
                  Delete
                </div>}
              </td>
            </tr>
          ))}
      </>
    );
  }

  findFlags() {
    if (this.props.noteFlags.length) {
      return (
        <div className="table-wrapper-scroll-y" style={{ overflow: 'hidden scroll', maxHeight: '400px' }}>
          <table className="table table-striped table-bordered table-hover bg-white mt-1 mr-3">
            <thead className="bg-dark thead-dark">
              <tr>
                <th className="text-center" style={{ width: '45%' }}>
                  Flag
                </th>
                <th className="text-center" style={{ width: '9%' }}>
                  Edit
                </th>
                <th className="text-center" style={{ width: '9%' }}>
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>{this.renderFlags()}</tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div style={{ padding: '.5rem' }}>
          No Flags...
        </div>
      );
    }
  }

  render() {
    return (
      <div id="noteFlagsModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Flags</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className='modal-body' style={{ padding: '0' }}>
              {this.findFlags()}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                data-target="#createNoteFlag"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                Create New Flag
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noteFlags: state.admin.admin.noteFlags,
  selectedFlag: state.admin.users.selectedFlag,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedFlag })(NoteFlagsModal);
