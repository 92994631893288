import React from 'react';
import './home.css';
import FeedItem from '../../utils/feeditem/feedItem.jsx';
import { connect } from 'react-redux';
import Logo from '../../../images/collective.png';
import FeedView from '../../utils/feedView/feedView.jsx';
import Page from '../../utils/page/page';
import ButtonColumn from '../../utils/buttonColumn/buttonColumn';
import { lock } from '../../../actions/authActions';
import {
  changeActiveTab,
  clearUnreadTally,
} from '../../../actions/collectivePageActions/collectiveActions.js';
import { updateShowButtons } from '../../../actions/collectivePageActions/homeActions.js';

class HomePage extends React.Component {
  render() {
    let feedItems;
    let newsItems;

    // Dynamically displays feed items
    if (this.props.feedItems.length === 0) {
      feedItems = <h1>No items to display</h1>;
    } else {
      feedItems = this.props.feedItems.map((log) => (
        <FeedItem
          title={ log.user }
          status={ log.status }
          messages={ log.messages }
          userId={ log.userId }
          id={ log.id }
          key={ log.id }
          history={ this.props.history }
          type='collective'
        />
      ));
    }

    // Dynamically displays news items
    if (this.props.newsItems.length === 0) {
      newsItems = <h1>No items to display</h1>;
    } else {
      newsItems = this.props.newsItems.map((news) => (
        <FeedItem
          title={ news.title }
          status={ news.read ? 'read' : 'unread' }
          messages={ [ news.message ] }
          id={ news.id }
          key={ news.id }
          history={ this.props.history }
          type='news'
        />
      ));
    }

    const menuButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/collective/register'),
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/collective/rfid'),
      },
      {
        text: 'Manual Check-in',
        onClick: () => this.props.history.push('/collective/checkin'),
      },
      {
        text: 'Rentals',
        onClick: () =>
          this.props.history.push('/collective/rentalscheckin'),
      },
      {
        text: 'Inventory',
        onClick: () => this.props.history.push('/collective/inventory'),
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock(),
      },
    ];

    return (
      <Page>
        <div className='collectivePageColumn'>
          <ButtonColumn logo={ Logo } buttons={ menuButtons } />
        </div>
        <div className='collectivePageColumn'>
          <FeedView
            activity={ feedItems }
            updates={ newsItems }
            activeTab={ this.props.activeTab }
            tabClick={ (index) => {
              this.props.changeActiveTab(index);
              this.props.clearUnreadTally();
              this.props.updateShowButtons(0);
            } }
            tally={ {
              newsUnreadTally: this.props.newsUnreadTally,
              activityUnreadTally: this.props.activityUnreadTally,
            } }
          ></FeedView>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  feedItems: state.collective.collective.feedItems,
  newsItems: state.collective.collective.newsItems,
  activeTab: state.collective.collective.activeTab,
  newsUnreadTally: state.collective.collective.newsUnreadTally,
  activityUnreadTally: state.collective.collective.activityUnreadTally,
});

export default connect(mapStateToProps, {
  changeActiveTab,
  clearUnreadTally,
  lock,
  updateShowButtons,
})(HomePage);
