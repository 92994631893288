import React, { Component } from 'react';
import '../../Modal.css';
import { skatePunches } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedSkatePunch } from '../../../../actions/adminActions/skatePunchActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      punches: '',
    };
  }

  async skatePunchUpdateRequest() {
    await skatePunches
      .patch(
        `/${ this.props.selectedskatePunch }`,
        {
          name: this.state.name,
          punches: this.state.punches,
          price: isNaN(this.state.price) ? 0 : this.state.price,
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => {
        console.log(response);
        alert('Sorry, an error occurred. Please check your entries.');
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedskatePunch !== this.props.selectedskatePunch &&
      this.props.selectedskatePunch !== 0
    ) {
      const skatePunch = this.props.skatePunches.find(
        (obj) => obj.id === this.props.selectedskatePunch
      );
      if (skatePunch) {
        this.setState({
          name: skatePunch.name,
          punches: skatePunch.punches,
          price: skatePunch.price,
        });
      }
    }
  }

  render() {
    return (
      <div
        id="exampleModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add skatePunch</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.name }
                  placeholder="Name"
                  onChange={ (e) => this.setState({ name: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Punches
                  </span>
                </div>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.punches }
                  placeholder="Punches"
                  onChange={ (e) => this.setState({ punches: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Price
                  </span>
                </div>
                <input
                  type="number"
                  min="0"
                  step={ 0.01 }
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.price }
                  placeholder="0.00"
                  onChange={ (e) => {
                    this.setState({
                      price: e.target.value,
                    });
                  } }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.skatePunchUpdateRequest();
                } }
                disabled={
                  !(
                    this.state.name !== '' &&
                    this.state.price !== '' &&
                    this.state.punches !== '' &&
                    this.state.price >= 0 &&
                    this.state.punches >= 0
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  skatePunches: state.admin.admin.skatePunches,
  selectedskatePunch: state.admin.skatePunches.selectedSkatePunch,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedSkatePunch })(
  EditModal
);
