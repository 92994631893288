export const updateAccessFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_ACCESS_PAGE_FILTER_STRING',
    payload: filterString,
  });
};

export const updateSelectedAccess = (access) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_ACCESS_PAGE_SELECTED_ACCESS',
    payload: access,
  });
};
