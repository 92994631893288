const INITIAL_STATE = {
  scannerFilter: '',
  selectedScanner: 0,
  registrationReady: false,
  raspberryPiSelected: null
};

export const scannersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_SCANNERS_PAGE_FILTER_STRING':
      return {
        ...state,
        scannerFilter: action.payload,
      };
    case 'UPDATE_ADMIN_SCANNERS_PAGE_SELECTED_SCANNER':
      return {
        ...state,
        selectedScanner: action.payload,
      };
    case 'UPDATE_ADMIN_SCANNERS_PAGE_REGISTER_SCANNER':
      return {
        ...state,
        registrationReady: action.payload
      };
    case 'UPDATE_ADMIN_SCANNERS_PAGE_PI_SELECTED':
      return {
        ...state,
        raspberryPiSelected: action.payload
      };
    default:
      return state;
  }
};
