export const updateEventFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_EVENTS_PAGE_FILTER_STRING',
    payload: filterString,
  });
};

export const updateSelectedEvent = (Event) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_EVENTS_PAGE_SELECTED_EVENT', payload: Event });
};
