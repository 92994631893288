export const updateShowButtons = (number) => (dispatch, getState) => {
  if (getState().collective.home.showButtons === number) {
    dispatch({
      type: 'SHOW_COLLECTIVE_BUTTONS',
      payload: 0
    });
  } else {
    dispatch({
      type: 'SHOW_COLLECTIVE_BUTTONS',
      payload: number
    });
  }
};
