import React, { Component } from 'react';
import '../../Modal.css';
import { employeeAuth } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedEmployee } from '../../../../actions/adminActions/employeesActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      pin: '',
      skatepark: '',
      collective: '',
      events: '',
      coffee: '',
    };
  }

  async employeeUpdateRequest() {
    await employeeAuth
      .patch(`/${ this.props.selectedEmployee }`, {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        pin: this.state.pin,
      }, { headers: { authorization: this.props.employeeToken } })
      .catch((response) => {
        this.setState({
          firstName: '',
          lastName: '',
          pin: '',
        });
        console.log(response);
        alert('Sorry, an error occurred. Please check your entries.');
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedEmployee !== this.props.selectedEmployee &&
      this.props.selectedEmployee !== 0
    ) {
      const employee = this.props.employees.find(
        (obj) => obj.id === this.props.selectedEmployee
      );
      if (employee) {
        this.setState({
          firstName: employee.firstName,
          lastName: employee.lastName,
          pin: employee.pin,
        });
      }
    }
  }

  render() {
    return (
      <div
        id="exampleModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ () => this.props.updateSelectedEmployee(0) }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    First Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.firstName }
                  placeholder="First Name"
                  onChange={ (e) => this.setState({ firstName: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Last Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.lastName }
                  placeholder="Last Name"
                  onChange={ (e) => this.setState({ lastName: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    PIN
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.pin }
                  placeholder="0000"
                  onChange={ (e) => this.setState({ pin: e.target.value }) }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={ () => this.props.updateSelectedEmployee(0) }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.employeeUpdateRequest();
                  this.props.updateSelectedEmployee(0);
                } }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employees: state.admin.admin.employees,
  selectedEmployee: state.admin.employees.selectedEmployee,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedEmployee })(EditModal);
