import React from 'react';
import './scanners.css';
import { connect } from 'react-redux';
import {
  updateScannerFilter,
  updateSelectedScanner,
  updateRegistrationReady,
} from '../../../actions/adminActions/scannersActions';
import { raspberrypi } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './scannerModals/editModal.jsx';
import AddModal from './scannerModals/addModal.jsx';
import WiFiModal from './scannerModals/wifiModal.jsx';
import RegistrationModal from './scannerModals/registrationModal';

class Scanners extends React.Component {
  async scannerDeleteRequest(id) {
    await raspberrypi.delete(`/${ id }`, { headers: { authorization: this.props.employeeToken } })
        .catch((response) => {
          console.log(response);
        });
  }

  getLocation(locationId) {
    const location = this.props.systemLocations.find(
      (location) => location.id === locationId
    ).location;
    return `${ location.charAt(0).toUpperCase() }${ location.slice(1) }`;
  }

  renderscanners() {
    //renders the scanners table
    return (
      <>
        {this.props.scanners
          .filter((scanner) => {
            const filterString = `${ scanner.nickname } ${ this.getLocation(
              scanner.location
            ) } ${ scanner.mac }`;
            return (
              filterString
                .toLowerCase()
                .indexOf(this.props.scannerFilter.toLowerCase()) > -1
            );
          })
          .map((scanner) => (
            <tr key={ this.props.scanners.indexOf(scanner) }>
              <td className="text-center" style={ { width: '20%' } }>
                {`${ scanner.nickname }`}
              </td>
              <td className="text-center" style={ { width: '30%' } }>
                {this.getLocation(scanner.location)}
              </td>
              <td className="text-center " style={ { width: '20%' } }>
                {scanner.mac}
              </td>
              <td className="text-center " style={ { width: '9%' } }>
                <div
                  data-target="#editModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedScanner(scanner.id);
                  } }
                >
                  Edit
                </div>
              </td>
              <td className="text-center " style={ { width: '9%' } }>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedScanner(scanner.id);
                  } }
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getScannerName() {
    const scanner = this.props.scanners.find(
      (obj) => obj.id === this.props.selectedScanner
    );

    return scanner ? scanner.nickname : '';
  }

  render() {
    return (
      <div className="scanners-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px', width: '100%' } }>
              Scanners
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={ this.props.scannerFilter }
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={ (e) => {
                this.props.updateScannerFilter(e.target.value);
              } }
            />
            <button
              data-target="#registerModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
              onClick={ () => this.props.updateRegistrationReady(true) }
            >
              Register Scanner
            </button>
            <div
              data-target="#wifiModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
            >
              Update WiFi
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={ { width: '20%' } }>
                    Name
                  </th>
                  <th className="text-center" style={ { width: '30%' } }>
                    Location
                  </th>
                  <th className="text-center" style={ { width: '20%' } }>
                    MAC
                  </th>
                  <th className="text-center " style={ { width: '9%' } }>
                    Edit
                  </th>
                  <th className="text-center " style={ { width: '9%' } }>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderscanners()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <RegistrationModal />

        <WiFiModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={ { width: '80%', margin: '0 auto' } }
            >
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => {
                    this.props.updateSelectedScanner(0);
                  } }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={ { textAlign: 'center', fontSize: '18px' } }
              >
                <strong>'{this.getScannerName()}'</strong> will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.scannerDeleteRequest(this.props.selectedScanner);
                    this.props.updateSelectedScanner(0);
                  } }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.props.updateSelectedScanner(0);
                  } }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scanners: state.admin.admin.raspberryPis,
  scannerFilter: state.admin.scanners.scannerFilter,
  selectedScanner: state.admin.scanners.selectedScanner,
  systemLocations: state.admin.admin.systemLocations,
  registrationReady: state.admin.scanners.registrationReady,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateScannerFilter,
  updateSelectedScanner,
  updateRegistrationReady,
})(Scanners);
