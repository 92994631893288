const INITIAL_STATE = {
  employeeFilter: '',
  selectedEmployee: 0,
};

export const employeesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_EMPLOYEE_PAGE_FILTER_STRING':
      return {
        ...state,
        employeeFilter: action.payload,
      };
    case 'UPDATE_ADMIN_EMPLOYEE_PAGE_SELECTED_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    default:
      return state;
  }
};
