import React, { Component } from 'react';
import './../../Modal.css';
import {
  updateSelectedFlag,
} from '../../../../actions/adminActions/usersActions';
import { connect } from 'react-redux';

class UserFiltersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRentalToggle: this.props.rentalFilter,
      userDuplicateToggle: this.props.duplicateFilter,
    };
  }

  async setUserFilters() {
    this.props.setFilters(this.state);
  }

  render() {
    return (
      <div id="userFiltersModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">User Filters</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className='modal-body' style={{ padding: '0' }}>
              <div className="input-group input-group-sm mb-3 mt-3" style={{ padding: '0 10px' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Active Rental Filter
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.userRentalToggle}
                  onChange={(e) => {
                    const value = e.target.value === 'true' ? true : false;
                    this.setState({ userRentalToggle: value });
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
              <div className="input-group input-group-sm mb-3" style={{ padding: '0 10px' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Duplicate User Filter
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.userDuplicateToggle}
                  onChange={(e) => {
                    const value = e.target.value === 'true' ? true : false;
                    this.setState({ userDuplicateToggle: value });
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                disabled={
                  this.state.userRentalToggle === this.props.rentalFilter &&
                  this.state.userDuplicateToggle === this.props.duplicateFilter
                }
                onClick={() => {
                  this.setUserFilters();
                }}
              >
                Set Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noteFlags: state.admin.admin.noteFlags,
  selectedFlag: state.admin.users.selectedFlag,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedFlag })(UserFiltersModal);
