import React from 'react';
import {
  addToCart,
  removeFromCart,
  updateFilter,
  initializeRentalsPage,
  clearRentalsPage,
} from '../../../actions/skateparkPageActions/rentalActions.js';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';
import { connect } from 'react-redux';
import './rentals.css';
import NotFound from '../../404/404';
import { rentalsOptions, bypassPurchase } from '../../../apis/backend';
import Loading from '../../loading/loading';

class Rentals extends React.Component {
  async componentDidMount() {
    await rentalsOptions
      .get(`/${ this.props.match.params.id }`, {
        params: {
          location: this.props.locationId,
        },
        headers: {
          authorization: this.props.employeeToken
        }
      })
      .then((response) => {
        this.props.initializeRentalsPage(response.data);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    this.props.clearRentalsPage();
  }

  handleCheckout = async (amount, id, cart) => {
    const stateObject = {
      user: id,
      cart: cart,
      type: 'rental',
      location: this.props.locationId,
      empUser: this.props.empUserId,
    };

    // Bypasses Square if price is $0
    if (amount === 0) {
      await bypassPurchase
        .post('/', { state: stateObject }, { headers: { authorization: this.props.employeeToken } })
        .catch((err) => console.log(err));
    } else {
      const state = JSON.stringify(stateObject);
      const user = this.props.users.find(
        (user) => user.id === parseInt(this.props.match.params.id)
      );
      let notes = `${ user.firstName } ${ user.lastName } rented:\n`;
      for (const item of cart) {
        notes += `• ${ item.type } - ${ item.number }: $${ item.price }\n`;
      }

      var dataParameter = {
        /* eslint-disable camelcase */
        amount_money: {
          amount: amount,
          currency_code: 'USD',
        },

        // Replace this value with your application's callback URL
        callback_url:
          // 'https://webhook.site/573d1875-a545-413b-b63a-f733cd1d9b13',
          // 'https://d7b4a553.ngrok.io/skatepark/purchaseConfirmation/',
          'thebay://',

        // Replace this value with your application's ID
        client_id: 'sq0idp-Twg8oP3IDO7TalQhddy7Ow',

        version: '1.3',
        state: state,
        notes: notes,
        options: {
          supported_tender_types: [
            'CREDIT_CARD',
            'CASH',
            'OTHER',
            'SQUARE_GIFT_CARD',
            'CARD_ON_FILE',
          ],
          auto_return: true,
        },
        location_id: '609EGP8M9GT1H',
        /* eslint-enable camelcase */
      };

      // console.log(dataParameter);
      const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (iOS) {
        window.webkit.messageHandlers.openSquare.postMessage({
          url:
            'square-commerce-v1://payment/create?data=' +
            encodeURIComponent(JSON.stringify(dataParameter)),
        });
      } else {
        alert(
          'To complete purchase, you must be on an iOS device with the Square POS app installed.'
        );
      }
    }

    this.props.history.push('/skatepark');
  };

  renderRentalItemOptions() {
    const rentalItemOptions = {
      columns: [ { width: 40 }, { width: 40 }, { width: 20 } ],
      titles: [ 'Type', 'Number', 'Price' ],
      entries: [],
      selectEvent: this.props.addToCart,
    };
    this.props.rentalOptions
      .filter((item) => {
        const fullItem = `${ item.type } ${ item.number } ${ item.price }`;
        return (
          fullItem.toLowerCase().indexOf(this.props.filter.toLowerCase()) >
          -1 &&
          !this.props.cart.includes(this.props.rentalOptions.indexOf(item))
        );
      })
      .forEach((item) => {
        rentalItemOptions.entries.push({
          id: this.props.rentalOptions.indexOf(item),
          columns: [ item.type, item.number, '$' + item.price.toFixed(2) ],
        });
      });
    return rentalItemOptions;
  }

  renderCart() {
    const rentalItemOptions = {
      columns: [ { width: 40 }, { width: 40 }, { width: 20 } ],
      titles: [ 'Type', 'Number', 'Price' ],
      entries: [],
      selectEvent: this.props.removeFromCart,
    };
    this.props.rentalOptions
      .filter((item) =>
        this.props.cart.includes(this.props.rentalOptions.indexOf(item))
      )
      .forEach((item) => {
        rentalItemOptions.entries.push({
          id: this.props.rentalOptions.indexOf(item),
          columns: [ item.type, item.number, '$' + item.price.toFixed(2) ],
        });
      });
    return rentalItemOptions;
  }

  getTotal() {
    let total = 0;
    for (const item of this.props.cart) {
      total += this.props.rentalOptions[ item ].price;
    }

    const user = this.props.users.find(
      (user) => user.id === parseInt(this.props.match.params.id)
    );
    //console.log(user);

    return {
      total: `${ user.firstName !== null ? user.firstName : '' } ${ user.lastName !== null ? user.lastName.substring(0, 1) : ''
      }. - Total: ${ total }`,
    };
  }

  // Gets the total price
  getTotalPrice() {
    let total = 0;
    for (const item of this.props.cart) {
      total += this.props.rentalOptions[ item ].price * 100;
    }

    return total;
  }

  render() {
    if (
      !this.props.users
        .map((user) => user.id)
        .includes(parseInt(this.props.match.params.id))
    ) {
      return <NotFound />;
    }
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Page>
        <div className="purchase-top-scroll-view-container">
          <div className="purchase-menu-list">
            <ScrollView
              options={this.renderRentalItemOptions()}
              formatOption={true}
              searchBar={{
                filterString: this.props.purchaseItemFilter,
                onChange: this.props.updatePurchaseItemFilter,
              }}
            ></ScrollView>
          </div>
          <div className="purchase-menu-list">
            <ScrollView
              options={this.renderCart()}
              formatOption={true}
              total={this.getTotal()}
            ></ScrollView>
          </div>
        </div>
        <div className="purchasePageButtonRowContain">
          <div
            className="purchasePageButtonContain"
            style={{ justifyContent: 'flex-start' }}
          >
            <button
              className="purchasePageButton"
              onClick={() => this.props.history.push('/skatepark')}
            >
              Back
            </button>
          </div>
          <div className="purchasePageButtonContain" />
          <div
            className="purchasePageButtonContain"
            style={{ justifyContent: 'flex-end' }}
          >
            <button
              className="purchasePageButton"
              onClick={() => {
                const cart = [];
                // console.log(this.props.rentalOptions);
                for (const itemId of this.props.cart) {
                  // console.log(this.props.rentalOptions[itemId]);
                  cart.push(this.props.rentalOptions[ itemId ]);
                }
                // console.log(cart);
                this.handleCheckout(
                  this.getTotalPrice(),
                  this.props.match.params.id,
                  cart
                );
              }}
              disabled={!this.props.cart.length}
            >
              Check-Out
            </button>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.skatepark.rentals.cart,
  filter: state.skatepark.rentals.filter,
  users: state.skatepark.skatepark.users,
  rentalOptions: state.skatepark.rentals.rentalOptions,
  loaded: state.skatepark.rentals.loaded,
  locationId: state.auth.sysLocationId,
  empUserId: state.auth.empId,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  addToCart,
  removeFromCart,
  initializeRentalsPage,
  updateFilter,
  clearRentalsPage,
})(Rentals);
