const INITIAL_STATE = {
  userFilter: '',
  selectedUser: 0,
  selectedNote: 0,
  selectedFlag: 0,
};

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_USERS_PAGE_FILTER_STRING':
      return {
        ...state,
        userFilter: action.payload,
      };
    case 'UPDATE_ADMIN_USERS_PAGE_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload,
      };
    case 'UPDATE_ADMIN_USERS_NOTE_SELECTED_USER':
      return {
        ...state,
        selectedNote: action.payload,
      };
    case 'UPDATE_ADMIN_NOTE_FLAGS_SELECTED_FLAG':
      return {
        ...state,
        selectedFlag: action.payload,
      };
    default:
      return state;
  }
};
