import React from 'react';
import './notes.css';
import { connect } from 'react-redux';
import ScrollView from '../../utils/scrollView/scrollView';
import Page from '../../utils/page/page';
import {
  updateSkateparkNoteModalId,
  updateSkateparkNoteModalVisibility,
  updateSkateparkNoteModalNote,
  clearSkateparkNoteModal,
} from '../../../actions/skateparkPageActions/skateparkPopupActions';
import CreateUserNote from '../../utils/popups/createUserNote';

class Notes extends React.Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.userId);
    this.props.updateSkateparkNoteModalId(id);
  }

  componentWillUnmount() {
    this.props.clearSkateparkNoteModal();
  }

  updateModalNote(note) {
    this.props.updateSkateparkNoteModalNote(note);
  }

  clearModalData() {
    this.props.clearSkateparkNoteModal();
  }

  renderUserNotes() {
    const user = this.props.users.filter(
      (obj) => obj.id === parseInt(this.props.match.params.userId)
    );
    const userNotesOptions = {
      columns: [
        { width: 75 },
        { width: 25 },
      ],
      titles: [ `${ user[ 0 ].firstName } ${ user[ 0 ].lastName }'s Notes`, 'Timestamp', ],
      entries: [],
      userNotes: true,
    };
    const notes = this.props.userNotes.filter(
      (obj) => obj.user === parseInt(this.props.match.params.userId)
    ).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    notes.forEach((note) => {
      userNotesOptions.entries.push({
        id: note.id,
        columns: [
          note.note,
          new Date(note.timestamp).toString().split(' GMT')[ 0 ],
        ]
      });
    });
    return userNotesOptions;
  }

  renderUserNotesButtons() {
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/skatepark'),
      },
      right: {
        title: 'Create Note',
        onClick: () => this.props.updateSkateparkNoteModalVisibility(true),
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={ this.renderUserNotes() }
          formatOption={false}
          buttons={ this.renderUserNotesButtons() }
        />
        <CreateUserNote
          note={ this.props.note }
          id={ this.props.id }
          visible={ this.props.visible }
          updateNote={ this.updateModalNote.bind(this) }
          clearModal={ this.clearModalData.bind(this) }
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.skatepark.skatepark.users,
  userNotes: state.skatepark.skatepark.userNotes,
  note: state.skatepark.skateparkPopup.userNote,
  id: state.skatepark.skateparkPopup.noteUserId,
  visible: state.skatepark.skateparkPopup.noteVisible,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateSkateparkNoteModalId,
  updateSkateparkNoteModalVisibility,
  updateSkateparkNoteModalNote,
  clearSkateparkNoteModal,
})(Notes);
