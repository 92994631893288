import React from 'react';
import './singleFeedView.css';

export default class SingleFeedView extends React.Component {
  render() {
    return (
      <>
        <div className="singleFeedContainer">
          <div className="singleFeed">
            <div style={ { margin: '10px 10px 0 10px' } }>
              { this.props.activity ? ( this.props.activity ) : ( <h1>No items to display</h1> ) }
            </div>
          </div>
        </div>
      </>
    );
  }
}
