import React from 'react';
import './selectEvent.css';
import {
  updateName,
  updateSelected,
  clearSelection,
  updateFilter
} from '../../../actions/eventsPageActions/selectEventActions';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';
import { lock } from '../../../actions/authActions';

class SelectEvent extends React.Component {
  componentWillUnmount() {
    this.props.clearSelection();
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    return `${ date.toDateString() }, ${ date.toLocaleTimeString() }`;
  }

  renderCheckInOptions() {
    const checkinOptions = {
      columns: [ { width: 38 }, { width: 31 }, { width: 31 } ],
      titles: [ 'Event', 'Start Time', 'End Time' ],
      entries: [],
      selectEvent: this.props.updateSelected,
      selected: this.props.selected,
    };
    this.props.events
      .filter(event => {
        const name = event.eventName;
        const type = event.createdBy;
        const nameFilter = name.toLowerCase().indexOf(this.props.name.toLowerCase()) > -1;
        const typeFilter = type === this.props.filter;
        if (this.props.filter === '') {
          return nameFilter;
        }
        return (nameFilter && typeFilter);
      })
      .forEach(event => {
        checkinOptions.entries.push({
          id: event.id,
          columns: [
            event.eventName,
            this.formatDate(event.startTime),
            this.formatDate(event.endTime)
          ]
        });
      });
    return checkinOptions;
  }

  renderFilterOptions() {
    const filterOptions = {
      options: [
        {
          width: 33.33,
          title: 'All',
          value: '',
          onClick: () => {
            this.props.updateFilter('');
          }
        },
        {
          width: 33.33,
          title: 'Eventbrite',
          value: 'eventbrite',
          onClick: () => {
            this.props.updateFilter('eventbrite');
          } },
        {
          width: 33.33,
          title: 'Non-Eventbrite',
          value: 'admin',
          onClick: () => {
            this.props.updateFilter('admin');
          }
        }
      ],
      selected: this.props.filter,
    };
    return filterOptions;
  }

  renderCheckInButtons() {
    return {
      left: {
        title: 'Lock',
        onClick: () => {
          this.props.lock();
        }
      },
      right: {
        title: this.props.button.title,
        onClick: () => {
          if(this.props.button.clickEvent){
            this.props.button.clickEvent(this.props.selected);
          }
          if(this.props.button.redirect) {
            this.props.history.push(this.props.button.redirect);
          }
        },
        disabled: (this.props.selected === 0)
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={ this.renderCheckInOptions() }
          filterOptions={ this.renderFilterOptions() }
          formatOption={false}
          buttons={ this.renderCheckInButtons() }
          searchBar={ {
            filterString: this.props.name,
            onChange: this.props.updateName
          } }
        />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  name: state.events.selectEvent.name,
  users: state.events.events.users,
  events: state.events.events.events,
  selected: state.events.selectEvent.selected,
  filter: state.events.selectEvent.filter
});

export default connect(mapStateToProps, {
  updateName,
  lock,
  updateSelected,
  clearSelection,
  updateFilter,
})(SelectEvent);
