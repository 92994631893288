import React from 'react';
import './checkin.css';
import {
  updateName,
  updateSelected,
  clearSelection
} from '../../../actions/coffeePageActions/checkinActions';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';

class Checkin extends React.Component {
  componentWillUnmount() {
    this.props.clearSelection();
  }

  renderCheckInOptions() {
    const checkinOptions = {
      columns: [ { width: 75 }, { width: 25 } ],
      titles: [ 'Name', 'Phone Number' ],
      entries: [],
      selectEvent: this.props.updateSelected,
      selected: this.props.selected
    };
    this.props.users
            .filter(user => {
              const fullname = `${ user.firstName } ${ user.lastName }`;
              return (
                fullname
                        .toLowerCase()
                        .indexOf(this.props.name.toLowerCase()) > -1
              );
            })
            .forEach(user => {
              checkinOptions.entries.push({
                id: user.id,
                columns: [
                  `${ user.firstName } ${ user.lastName }`,
                  user.phoneNumber
                ]
              });
            });
    return checkinOptions;
  }

  renderCheckInButtons() {
    const selectedObject = this.props.users.find(
            user => user.id === this.props.selected
    );
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/coffee')
      },
      right: {
        title: this.props.button.title,
        onClick: () => {
          if(this.props.button.clickEvent){
            this.props.button.clickEvent(selectedObject.id);
          }
          if(this.props.button.redirect) {
            this.props.history.push(this.props.button.redirect);
          }
          if(this.props.button.redirectId) {
            this.props.history.push(`${ this.props.button.redirectId }/${ selectedObject.id }`);
          }
        },
        disabled: (this.props.selected === 0)
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={ this.renderCheckInOptions() }
          buttons={ this.renderCheckInButtons() }
          searchBar={ {
            filterString: this.props.name,
            onChange: this.props.updateName
          } }
        />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  name: state.coffee.checkin.name,
  users: state.coffee.coffee.users,
  selected: state.coffee.checkin.selected
});

export default connect(mapStateToProps, {
  updateName,
  updateSelected,
  clearSelection
})(Checkin);
