import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedEvent } from '../../../../actions/adminActions/eventsActions';
import { events } from '../../../../apis/backend';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      eventName: '',
      cashAttendance: 0,
      ticketAttendance: 0,
      allAccessAttendance: 0,
      startTime: '',
      startDate: '',
      endTime: '',
      endDate: '',
    };
  }

  getDate(time) {
    const date = new Date(new Date(time).getTime() - 5 * 60 * 60 * 1000);
    const dateString = date.toISOString().split('T')[ 0 ];
    return dateString;
  }

  getTime(time) {
    const date = new Date(new Date(time).getTime() - 5 * 60 * 60 * 1000);
    const timeString = date.toISOString().split('T')[ 1 ].replace('Z', '');
    return timeString;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedEvent !== this.props.selectedEvent &&
      this.props.selectedEvent !== 0
    ) {
      const event = this.props.events.find(
        (obj) => obj.id === this.props.selectedEvent
      );
      if (event) {
        this.setState({
          eventName: event.eventName,
          cashAttendance: event.cashAttendance,
          ticketAttendance: event.ticketAttendance,
          allAccessAttendance: event.allAccessAttendance,
          startTime: this.getTime(event.startTime),
          startDate: this.getDate(event.startTime),
          endTime: this.getTime(event.endTime),
          endDate: this.getDate(event.endTime),
        });
      }
    }
  }

  async eventRequest() {
    try {
      await events
        .patch(`/${ this.props.selectedEvent }`, {
          // "field in the db that you are adding: data to be added"
          // look in the backend/api/models to see which fields are required
          id: this.state.id,
          eventName: this.state.eventName,
          cashAttendance: this.state.cashAttendance,
          ticketAttendance: this.state.ticketAttendance,
          allAccessAttendance: this.state.allAccessAttendance,
          startTime: this.state.startDate + 'T' + this.state.startTime,
          endTime: this.state.endDate + 'T' + this.state.endTime,
        }, { headers: { authorization: this.props.employeeToken } })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div
        id="exampleModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add event</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Event Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.eventName }
                  onChange={ (e) => this.setState({ eventName: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Start Date
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.startDate }
                  onChange={ (e) => this.setState({ startDate: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Start Time
                  </span>
                </div>
                <input
                  type="time"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.startTime }
                  onChange={ (e) => this.setState({ startTime: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    End Date
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.endDate }
                  onChange={ (e) => this.setState({ endDate: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    End Time
                  </span>
                </div>
                <input
                  type="time"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.endTime }
                  onChange={ (e) => this.setState({ endTime: e.target.value }) }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.eventRequest();
                } }
                disabled={
                  !(
                    this.state.eventName !== '' &&
                    this.state.cashAttendance !== '' &&
                    this.state.ticketAttendance !== '' &&
                    this.state.allAccessAttendance !== '' &&
                    this.state.startTime !== '' &&
                    this.state.endTime !== '' &&
                    this.state.startDate !== '' &&
                    this.state.endDate !== '' &&
                    (this.state.endDate > this.state.startDate ||
                      (this.state.startDate === this.state.endDate && this.state.endTime >= this.state.startTime)
                    )
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.admin.admin.events,
  selectedEvent: state.admin.events.selectedEvent,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedEvent })(EditModal);
