export const initializeLogin = () => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_LOGIN'
  });
};

export const updateUser = (username) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOGIN_USERNAME',
    payload: username
  });
};

export const updatePass = (password) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOGIN_PASSWORD',
    payload: password
  });
};

export const updateErrorMessage = (message) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOGIN_ERROR_MESSAGE',
    payload: message
  });
};
