import React, { Component } from 'react';
import './../../Modal.css';
import { inventoryType } from '../../../../apis/backend';
import { connect } from 'react-redux';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      location: '',
    };
  }

  async inventoryTypeRequest() {
    try {
      console.log(this.state.location);
      await inventoryType
        .post(
          '/',
          {
            // "field in the db that you are adding: data to be added"
            // look in the backend/api/models to see which fields are required
            name: this.state.name,
            price: isNaN(this.state.price) ? 0 : this.state.price,
            location: this.state.location,
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => {
          this.setState({
            name: '',
            price: '',
            location: '',
          });
        })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={ { overflow: 'scroll' } }>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Inventory Type</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.name }
                  placeholder="Inventory Type Name"
                  onChange={ (e) => this.setState({ name: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Price
                  </span>
                </div>
                <input
                  type="number"
                  step={ 0.01 }
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="0"
                  value={ this.state.price }
                  placeholder="0.00"
                  onChange={ (e) =>
                    this.setState({
                      price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Location
                  </span>
                </div>
                <select
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.location }
                  onChange={ (e) => {
                    this.setState({ location: e.target.value });
                  } }
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {this.props.systemLocations.map((location) => (
                    <option key={ location.id } value={ location.id }>
                      {location.location}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.inventoryTypeRequest();
                } }
                disabled={
                  !(
                    this.state.name !== '' &&
                    this.state.price !== '' &&
                    this.state.location !== '' &&
                    this.state.price >= 0
                  )
                }
              >
                Create Inventory Type
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemLocations: state.admin.admin.systemLocations,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {})(AddModal);
