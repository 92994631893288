export const initializeKeypad = () => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_KEYPAD'
  });
};

export const addToPin = (number) => (dispatch) => {
  dispatch({
    type: 'ADD_TO_PIN',
    payload: number
  });
};

export const removeOneFromPin = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_FROM_PIN'
  });
};

export const clearPin = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_PIN'
  });
};

export const playIncorrectAnimation = () => (dispatch) => {
  dispatch({
    type: 'PIN_INCORRECT_ON'
  });
};

export const stopIncorrectAnimation = () => (dispatch) => {
  dispatch({
    type: 'PIN_INCORRECT_OFF'
  });
};
