const INITIAL_STATE = {
  username: '',
  password: '',
  errorMessage: '',
};

export const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIALIZE_LOGIN':
      return INITIAL_STATE;
    case 'UPDATE_LOGIN_USERNAME':
      return {
        ...state,
        username: action.payload
      };
    case 'UPDATE_LOGIN_PASSWORD':
      return {
        ...state,
        password: action.payload
      };
    case 'UPDATE_LOGIN_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
