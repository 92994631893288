import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../Modal.css';
import { memberships } from '../../../../apis/backend';
import {
  updateSelectedMembership,
  updateMembershipDiscounts,
} from '../../../../actions/adminActions/membershipsActions';

class DiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discInv: [],
      discAcc: [],
      discPunch: [],
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedMembership !== this.props.selectedMembership &&
      this.props.selectedMembership !== 0
    ) {
      await memberships
        .get(`/discounts/${ this.props.selectedMembership }`, {
          headers: { authorization: this.props.employeeToken },
        })
        .then((res) => {
          this.setState({
            discInv: res.data.inventoryTypes,
            discAcc: res.data.access.filter((obj) => obj.hidden === false),
            discPunch: res.data.skatePunches,
          });
        })
        .catch((err) => console.log(err));
    }
  }

  renderInventoryTypes() {
    return (
      <>
        {this.state.discInv.map((inventory) => (
          <tr key={ this.state.discInv.indexOf(inventory) }>
            <td className="text-center" style={ { width: '65%' } }>
              {`${ inventory.name }`}
            </td>
            <td className="text-center " style={ { width: '25%' } }>
              {`${
                inventory.price >= 0
                  ? `$${ inventory.price.toFixed(2) }`
                  : 'No discount'
              }`}
            </td>
            <td className="text-center " style={ { width: '10%' } }>
              <div
                data-target="#updateModal"
                className="btn btn-sm btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={ () => {
                  this.props.updateSelectedMembership(0);
                  this.props.updateMembershipDiscounts(
                    this.props.selectedMembership,
                    inventory.id,
                    'inventory'
                  );
                } }
              >
                Update
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }

  renderAccess() {
    return (
      <>
        {this.state.discAcc.map((inventory) => (
          <tr key={ this.state.discAcc.indexOf(inventory) }>
            <td className="text-center" style={ { width: '65%' } }>
              {`${ inventory.name }`}
            </td>
            <td className="text-center " style={ { width: '25%' } }>
              {`${
                inventory.price
                  ? `$${ inventory.price.toFixed(2) }`
                  : 'No discount'
              }`}
            </td>
            <td className="text-center " style={ { width: '10%' } }>
              <div
                data-target="#updateModal"
                className="btn btn-sm btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={ () => {
                  this.props.updateSelectedMembership(0);
                  this.props.updateMembershipDiscounts(
                    this.props.selectedMembership,
                    inventory.id,
                    'access'
                  );
                } }
              >
                Update
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }

  renderSkatePunches() {
    return (
      <>
        {this.state.discPunch.map((inventory) => (
          <tr key={ this.state.discPunch.indexOf(inventory) }>
            <td className="text-center" style={ { width: '65%' } }>
              {`${ inventory.name }`}
            </td>
            <td className="text-center " style={ { width: '25%' } }>
              {`${
                inventory.price
                  ? `$${ inventory.price.toFixed(2) }`
                  : 'No discount'
              }`}
            </td>
            <td className="text-center " style={ { width: '10%' } }>
              <div
                data-target="#updateModal"
                className="btn btn-sm btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={ () => {
                  this.props.updateSelectedMembership(0);
                  this.props.updateMembershipDiscounts(
                    this.props.selectedMembership,
                    inventory.id,
                    'punches'
                  );
                } }
              >
                Update
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }

  getMembershipName() {
    if (this.props.selectedMembership !== 0) {
      const name = this.props.memberships.find(
        (obj) => obj.id === this.props.selectedMembership
      ).accessName;
      return `Edit '${ name }' Discounts`;
    } else {
      return 'Edit Membership Discounts';
    }
  }

  render() {
    return (
      <div
        className="modal fade"
        id="discountModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-fade">
          <div className="modal-dialog modal-dialog-centered lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {this.getMembershipName()}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => updateSelectedMembership(0) }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="users-modal-body">
                <div
                  className="table-wrapper-scroll-y"
                  style={ { overflowX: 'hidden' } }
                >
                  <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
                    <thead className="bg-dark thead-dark">
                      <tr>
                        <th className="text-center " style={ { width: '70%' } }>
                          Inventory Type
                        </th>
                        <th className="text-center" style={ { width: '20%' } }>
                          Price
                        </th>
                        <th className="text-center" style={ { width: '10%' } }>
                          Update
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderInventoryTypes()}</tbody>
                  </table>
                </div>
                <div
                  className="table-wrapper-scroll-y"
                  style={ { overflowX: 'hidden' } }
                >
                  <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
                    <thead className="bg-dark thead-dark">
                      <tr>
                        <th className="text-center " style={ { width: '70%' } }>
                          Access
                        </th>
                        <th className="text-center" style={ { width: '20%' } }>
                          Price
                        </th>
                        <th className="text-center" style={ { width: '10%' } }>
                          Update
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderAccess()}</tbody>
                  </table>
                </div>
                <div
                  className="table-wrapper-scroll-y"
                  style={ { overflowX: 'hidden' } }
                >
                  <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
                    <thead className="bg-dark thead-dark">
                      <tr>
                        <th className="text-center " style={ { width: '70%' } }>
                          Skate Punches
                        </th>
                        <th className="text-center" style={ { width: '20%' } }>
                          Price
                        </th>
                        <th className="text-center" style={ { width: '10%' } }>
                          Update
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderSkatePunches()}</tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => updateSelectedMembership(0) }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.membershipUpdateRequest();
                  } }
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  memberships: state.admin.admin.memberships,
  selectedMembership: state.admin.memberships.selectedMembership,
  inventoryTypes: state.admin.admin.inventoryTypes,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateSelectedMembership,
  updateMembershipDiscounts,
})(DiscountModal);
