import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { loginReducer } from './loginReducer';
import { keypadReducer } from './keypadReducer';

// Skatepark
import { skateparkReducer } from './skateparkPageReducers/skateparkReducer';
import { skateparkHomeReducer } from './skateparkPageReducers/homeReducer';
import { skateparkPurchaseReducer } from './skateparkPageReducers/purchaseReducer';
import { skateparkCheckinReducer } from './skateparkPageReducers/checkinReducer';
import { skateparkRentalsReducer } from './skateparkPageReducers/rentalReducer';
import { skateparkInventoryReducer } from './skateparkPageReducers/inventoryReducer';
import { skateparkRegisterRFIDReducer } from './skateparkPageReducers/rfidRegisterReducer';
import { skateparkPopupReducer } from './skateparkPageReducers/skateparkPopupReducer';

// Collective
import { collectiveReducer } from './collectivePageReducers/collectiveReducer';
import { collectiveHomeReducer } from './collectivePageReducers/homeReducer';
import { collectiveCheckinReducer } from './collectivePageReducers/checkinReducer';
import { collectiveRentalsReducer } from './collectivePageReducers/rentalReducer';
import { collectiveInventoryReducer } from './collectivePageReducers/inventoryReducer';
import { collectiveRegisterRFIDReducer } from './collectivePageReducers/rfidRegisterReducer';
import { collectiveInventoryEditPopupReducer } from './collectivePageReducers/collectiveInventoryEditPopupReducer';

// Events
import { eventsPageReducer } from './eventsPageReducers/eventsPageReducer';
import { eventsHomeReducer } from './eventsPageReducers/homeReducer';
import { eventsCheckinReducer } from './eventsPageReducers/checkinReducer';
import { eventsSelectReducer } from './eventsPageReducers/selectEventReducer';

// Coffee
import { coffeeReducer } from './coffeePageReducers/coffeeReducer';
import { coffeeHomeReducer } from './coffeePageReducers/homeReducer';
import { coffeeCheckinReducer } from './coffeePageReducers/checkinReducer';

// Admin
import { systemUsersReducer } from './adminReducers/adminSystemUsersReducer';
import { membershipsReducer } from './adminReducers/adminMembershipsReducer';
import { usersReducer } from './adminReducers/adminUsersReducers';
import { skatePunchesReducer } from './adminReducers/adminSkatePunchesReducer';
import { employeesReducer } from './adminReducers/adminEmployeesReducer';
import { eventsReducer } from './adminReducers/adminEventsReducer';
import { scannersReducer } from './adminReducers/scannersReducer';
import { adminReducer } from './adminReducers/adminReducer';
import { newsReducer } from './adminReducers/adminNewsReducer';
import { authReducer } from './authReducer';
import { inventoryReducer } from './adminReducers/adminInventoryReducer';
import { inventoryTypeReducer } from './adminReducers/adminInventoryTypeReducer';
import { purchaseLogReducer } from './adminReducers/adminPurchaseLogsReducer';
import { accessReducer } from './adminReducers/adminAccessReducer';
import { mealSwipesAllowanceReducer } from './adminReducers/adminMealSwipesAllowanceReducer';
import { reportingReducer } from './adminReducers/adminReportingReducer';

export default combineReducers({
  auth: authReducer,
  keypad: keypadReducer,
  application: appReducer,
  login: loginReducer,
  skatepark: combineReducers({
    skatepark: skateparkReducer,
    home: skateparkHomeReducer,
    purchase: skateparkPurchaseReducer,
    rentals: skateparkRentalsReducer,
    checkin: skateparkCheckinReducer,
    inventory: skateparkInventoryReducer,
    rfidRegister: skateparkRegisterRFIDReducer,
    skateparkPopup: skateparkPopupReducer,
  }),
  collective: combineReducers({
    collective: collectiveReducer,
    home: collectiveHomeReducer,
    rentals: collectiveRentalsReducer,
    checkin: collectiveCheckinReducer,
    inventory: collectiveInventoryReducer,
    rfidRegister: collectiveRegisterRFIDReducer,
    collectiveInventoryEditPopup: collectiveInventoryEditPopupReducer,
  }),
  events: combineReducers({
    events: eventsPageReducer,
    home: eventsHomeReducer,
    checkin: eventsCheckinReducer,
    selectEvent: eventsSelectReducer,
  }),
  coffee: combineReducers({
    coffee: coffeeReducer,
    home: coffeeHomeReducer,
    checkin: coffeeCheckinReducer,
  }),
  admin: combineReducers({
    memberships: membershipsReducer,
    users: usersReducer,
    employees: employeesReducer,
    systemUsers: systemUsersReducer,
    events: eventsReducer,
    scanners: scannersReducer,
    news: newsReducer,
    admin: adminReducer,
    inventory: inventoryReducer,
    inventoryTypes: inventoryTypeReducer,
    skatePunches: skatePunchesReducer,
    purchaseLogs: purchaseLogReducer,
    access: accessReducer,
    allowance: mealSwipesAllowanceReducer,
    reporting: reportingReducer,
  }),
});
