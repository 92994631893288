import React from 'react';
import './employees.css';
import { connect } from 'react-redux';
import {
  updateEmployeeFilter,
  updateSelectedEmployee,
} from '../../../actions/adminActions/employeesActions';
import { employeeAuth } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './employeeModals/editModal.jsx';
import AddModal from './employeeModals/addModal.jsx';
import PermissionsModal from './employeeModals/permissionsModal';

class Employees extends React.Component {
  async employeeDeleteRequest(id) {
    await employeeAuth.delete(`/${ id }`, { headers: { authorization: this.props.employeeToken } }).catch((response) => {
      console.log(response);
    });
  }
  renderEmployees() {
    //renders the employees table
    return (
      <>
        {this.props.employees
          .filter((employee) => {
            const filterString = `${ employee.firstName } ${ employee.lastName } ${ employee.pin }`;
            return (
              filterString
                .toLowerCase()
                .indexOf(this.props.employeeFilter.toLowerCase()) > -1
            );
          })
          .map((employee) => (
            <tr key={ this.props.employees.indexOf(employee) }>
              <td className="text-center" style={ { width: '60%' } }>
                {`${ employee.firstName } ${ employee.lastName }`}
              </td>
              <td className="text-center" style={ { width: '10%' } }>
                {employee.pin}
              </td>
              <td className="text-center " style={ { width: '10%' } }>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedEmployee(employee.id);
                  } }
                >
                  Edit
                </div>
              </td>
              <td className="text-center " style={ { width: '10%' } }>
                <div
                  data-target="#permissionsModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedEmployee(employee.id);
                  } }
                >
                  Permissions
                </div>
              </td>
              <td className="text-center " style={ { width: '10%' } }>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  style={this.props.empId === employee.id ? { display: 'none' } : {}}
                  onClick={ () => {
                    this.props.updateSelectedEmployee(employee.id);
                  } }
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getEmployeeName() {
    const employee = this.props.employees.find(
      (obj) => obj.id === this.props.selectedEmployee
    );

    return employee ? employee.firstName + ' ' + employee.lastName : '';
  }

  render() {
    return (
      <div className="employees-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px', width: '100%' } }>
              Employees
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={ this.props.employeeFilter }
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={ (e) => {
                this.props.updateEmployeeFilter(e.target.value);
              } }
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
            >
              Add Employee
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={ { width: '60%' } }>
                    Name
                  </th>
                  <th className="text-center" style={ { width: '10%' } }>
                    PIN
                  </th>
                  <th className="text-center " style={ { width: '10%' } }>
                    Edit
                  </th>
                  <th className="text-center " style={ { width: '10%' } }>
                    Permissions
                  </th>
                  <th className="text-center " style={ { width: '10%' } }>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderEmployees()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <PermissionsModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={ { width: '80%', margin: '0 auto' } }
            >
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => {
                    this.props.updateSelectedEmployee(0);
                  } }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={ { textAlign: 'center', fontSize: '18px' } }
              >
                <strong>'{this.getEmployeeName()}'</strong> will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.employeeDeleteRequest(this.props.selectedEmployee);
                    this.props.updateSelectedEmployee(0);
                  } }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.props.updateSelectedEmployee(0);
                  } }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  empId: state.auth.empId,
  employees: state.admin.admin.employees,
  employeeFilter: state.admin.employees.employeeFilter,
  selectedEmployee: state.admin.employees.selectedEmployee,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateEmployeeFilter,
  updateSelectedEmployee,
})(Employees);
