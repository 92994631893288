export const updateShowButtons = (number) => (dispatch, getState) => {
  if (getState().events.home.showButtons === number) {
    dispatch({
      type: 'SHOW_EVENTS_BUTTONS',
      payload: 0
    });
  } else {
    dispatch({
      type: 'SHOW_EVENTS_BUTTONS',
      payload: number
    });
  }
};
