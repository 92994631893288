import React, { Component } from 'react';
import '../../Modal.css';
import { memberships } from '../../../../apis/backend';
import { connect } from 'react-redux';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessName: '',
      days: 0,
      months: 0,
      years: 0,
      price: '',
    };
  }

  getDuration() {
    const years = this.state.years * 365 * 24 * 60 * 60 * 1000;
    const months = this.state.months * 30 * 24 * 60 * 60 * 1000;
    const days = this.state.days * 24 * 60 * 60 * 1000;

    return years + months + days;
  }

  clearState() {
    this.setState({
      accessName: '',
      days: 0,
      months: 0,
      years: 0,
      price: '',
    });
  }

  async membershipRequest() {
    try {
      await memberships
        .post(
          '/',
          {
            accessName: this.state.accessName,
            duration: this.getDuration(),
            price: isNaN(this.state.price) ? 0 : this.state.price,
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Membership</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ () => this.clearState() }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.accessName }
                  placeholder="Name"
                  onChange={ (e) =>
                    this.setState({ accessName: e.target.value })
                  }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Price
                  </span>
                </div>
                <input
                  type="Number"
                  min="0"
                  step={ 0.01 }
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.price }
                  placeholder="0.00"
                  onChange={ (e) =>
                    this.setState({
                      price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Duration (Days)
                  </span>
                </div>
                <input
                  type="Number"
                  min="0"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.days }
                  placeholder="0"
                  onChange={ (e) => this.setState({ days: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Duration (Months)
                  </span>
                </div>
                <input
                  type="Number"
                  min="0"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.months }
                  placeholder="0"
                  onChange={ (e) => this.setState({ months: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Duration (Years)
                  </span>
                </div>
                <input
                  type="Number"
                  min="0"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.years }
                  placeholder="0"
                  onChange={ (e) => this.setState({ years: e.target.value }) }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={ () => this.clearState() }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.membershipRequest();
                } }
                disabled={
                  !(
                    this.state.name !== '' &&
                    this.state.price !== '' &&
                    this.state.days !== '' &&
                    this.state.months !== '' &&
                    this.state.years !== '' &&
                    this.state.price >= 0 &&
                    this.state.days >= 0 &&
                    this.state.months >= 0 &&
                    this.state.years >= 0
                  )
                }
              >
                Create Membership
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {})(AddModal);
