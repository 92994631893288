import React from 'react';
import './inventory.css';
import { connect } from 'react-redux';
import {
  clearInventoryPage,
  updateSelectedInventory,
  updateInventoryFilter,
} from '../../../actions/collectivePageActions/inventoryActions';
import { rentals } from '../../../apis/backend';
import Checkmark from '../../../images/checkmark.png';
import Xmark from '../../../images/xmark.png';
import Warning from '../../../images/warning.png';
import ScrollView from '../../utils/scrollView/scrollView';
import Page from '../../utils/page/page';

class Inventory extends React.Component {
  componentWillUnmount() {
    this.props.clearInventoryPage();
  }

  async handleCheckIn() {
    await rentals
      .patch(
        `/checkin/${
          this.props.inventory.find(
            (inv) => inv.id === this.props.selectedInventory
          ).rentalId
        }`,
        {},
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((err) => {
        console.log(err);
      });
  }

  renderInventoryStatus(inventory) {
    let indicator;
    if (inventory.status === 'ready') {
      indicator = (
        <img style={ { maxHeight: '75%' } } src={ Checkmark } alt={ 'Ready' } />
      );
    } else if (inventory.status === 'warning') {
      indicator = (
        <img style={ { maxHeight: '75%' } } src={ Warning } alt={ 'Warning' }></img>
      );
    } else if (inventory.status === 'broken') {
      indicator = (
        <img style={ { maxHeight: '75%' } } src={ Xmark } alt={ 'Broken' }></img>
      );
    } else {
      indicator = (
        <img style={ { maxHeight: '75%' } } src={ Xmark } alt={ 'Broken' } />
      );
    }
    return indicator;
  }

  renderInventoryOptions() {
    const inventoryOptions = {
      columns: [
        { width: 20, align: 'center' },
        { width: 30, align: 'center' },
        { width: 35, align: 'center' },
        { width: 15, align: 'center' },
      ],
      titles: [ 'Item Number', 'Item Type', 'Availability', 'Status' ],
      entries: [],
      selectEvent: this.props.updateSelectedInventory,
      selected: this.props.selectedInventory,
    };
    this.props.inventory
      .filter((inventory) => {
        const fullname = `${ inventory.itemNumber } ${ inventory.type } ${ inventory.available } ${ inventory.status }`;
        return (
          fullname
            .toLowerCase()
            .indexOf(this.props.inventoryFilter.toLowerCase()) > -1
        );
      })
      .forEach((inventory) => {
        inventoryOptions.entries.push({
          id: inventory.id,
          columns: [
            inventory.itemNumber,
            inventory.type,
            inventory.available,
            this.renderInventoryStatus(inventory),
          ],
        });
      });
    return inventoryOptions;
  }

  renderInventoryButtons() {
    const selectedObject = this.props.inventory.find(
      (inventory) => inventory.id === this.props.selectedInventory
    );
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/collective'),
      },
      center: {
        title: 'Edit',
        onClick: () =>
          this.props.history.push(
            `/collective/inventory/edit/${ this.props.selectedInventory }`
          ),
        disabled: this.props.selectedInventory === 0,
      },
      right: {
        title: 'Check-In',
        onClick: () => this.handleCheckIn(),
        disabled: !(
          selectedObject &&
          selectedObject.available.toLowerCase() === 'checked-out'
        ),
      },
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={ this.renderInventoryOptions() }
          buttons={ this.renderInventoryButtons() }
          searchBar={ {
            filterString: this.props.inventoryFilter,
            onChange: this.props.updateInventoryFilter,
          } }
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  inventory: state.collective.collective.inventory,
  selectedInventory: state.collective.inventory.selectedInventory,
  inventoryFilter: state.collective.inventory.inventoryFilter,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  clearInventoryPage,
  updateSelectedInventory,
  updateInventoryFilter,
})(Inventory);
