const INITIAL_STATE = {
  purchaseLogFilter: '',
  selectedPurchaseLog: 0,
};

export const purchaseLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_PURCHASE_LOG_PAGE_FILTER_STRING':
      return {
        ...state,
        purchaseLogFilter: action.payload,
      };
    case 'UPDATE_ADMIN_PURCHASE_LOG_PAGE_SELECTED_PURCHASE_LOG':
      return {
        ...state,
        selectedPurchaseLog: action.payload,
      };
    default:
      return state;
  }
};
