import React from 'react';
import './reporting.css';
import { connect } from 'react-redux';
import { updateSelectedReport } from '../../../actions/adminActions/adminReportingActions';
import { reporting } from '../../../apis/backend'; //make sure to include this for each page

class Reporting extends React.Component {
  constructor(props) {
    super(props);
    let current = new Date();
    current = current.toISOString().split('T')[ 0 ];
    this.state = {
      fromDate: current,
      toDate: current,
      data: {
        headers: [],
        entries: [],
      },
    };
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    return `${ date.toDateString() }, ${ date.toLocaleTimeString() }`;
  }

  async getReportData() {
    await reporting
      .get(`/${ this.props.selectedReport }`, {
        params: {
          beginDate: this.state.fromDate,
          endDate: this.state.toDate,
        },
        headers: { authorization: this.props.employeeToken },
      })
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="reporting-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px', width: '100%' } }>
              Reporting
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <select
              value={ this.props.selectedReport }
              className="form-control form-control-lg"
              onChange={ (e) => {
                this.props.updateSelectedReport(e.target.value);
              } }
            >
              <option value="" disabled>
                Select a report
              </option>
              <option value="allcheckins">All Check-ins</option>
              <option value="skatepark">Skatepark Check-ins</option>
              <option value="collective">Collective Check-ins</option>
              <option value="venue">Venue Check-ins</option>
              <option value="mealswipes">Meal-Swipe Check-ins</option>
              <option value="skateparktotal">Skatepark Totals</option>
              <option value="collectivetotal">Collective Totals</option>
              <option value="venuetotal">Venue Totals</option>
              <option value="mealswipestotal">Meal-Swipe Total</option>
              <option value="crossengaging">Cross-Engagement</option>
              <option value="zipcode">Zipcode Stats</option>
              <option value="school">School</option>
              <option value="age">Age Stats</option>
              <option value="gender">Gender Stats</option>
              <option value="ethnicity">Ethnicity Stats</option>
            </select>
            <div className="input-group input-group-lg ml-2">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  From
                </span>
              </div>
              <input
                type="date"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={ this.state.fromDate }
                placeholder="From Date"
                onChange={ (e) => this.setState({ fromDate: e.target.value }) }
              />
            </div>
            <div className="input-group input-group-lg ml-2">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  To
                </span>
              </div>
              <input
                type="date"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={ this.state.toDate }
                placeholder="To Date"
                onChange={ (e) => this.setState({ toDate: e.target.value }) }
              />
            </div>
            <button
              className="btn btn-lg btn-dark"
              style={ { marginLeft: '10px' } }
              onClick={ () => this.getReportData() }
              disabled={ this.props.selectedReport === '' }
            >
              Run Report
            </button>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  {this.state.data.headers.map((head, index) => {
                    return (
                      <th
                        key={ index }
                        className="text-center"
                        style={ { width: 'auto' } }
                      >
                        {head}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.data.entries.map((entry, index) => {
                  return (
                    <tr key={ index }>
                      {this.state.data.headers.map((head, index2) => (
                        <td
                          className="text-center"
                          key={ index2 }
                          style={ { width: 'auto' } }
                        >
                          {head.toLowerCase() === 'date'
                            ? this.formatDate(entry[ head ])
                            : entry[ head ]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {this.state.data.entries.length > 0 ? (
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <tbody className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={ { width: '50%' } }>
                    Total:
                  </th>
                  <th className="text-center" style={ { width: '50%' } }>
                    {this.state.data.entries.length}
                  </th>
                </tr>
              </tbody>
              {this.state.data.entries[ 0 ][ 'All Access' ] ? (
                <tbody className="bg-dark thead-dark">
                  <tr>
                    <th className="text-center" style={ { width: '50%' } }>
                      Total All Access Pass:
                    </th>
                    <th className="text-center" style={ { width: '50%' } }>
                      {
                        this.state.data.entries.filter(
                          (entry) =>
                            entry[ 'All Access' ].toLowerCase() === 'true'
                        ).length
                      }
                    </th>
                  </tr>
                </tbody>
              ) : (
                <></>
              )}
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedReport: state.admin.reporting.selectedReport,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateSelectedReport,
})(Reporting);
