export const updateName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_COFFEE_CHECKIN_NAME',
    payload: name
  });
};

export const updateSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_COFFEE_CHECKIN_SELECTED',
    payload: getState().coffee.checkin.selected === id ? 0 : id
  });
};

export const clearSelection = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_COFFEE_CHECKIN_SELECTED'
  });
};
