export const updateMembershipFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_MEMBERSHIP_PAGE_FILTER_STRING',
    payload: filterString,
  });
};

export const updateSelectedMembership = (membership) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_MEMBERSHIP_PAGE_SELECTED_MEMBERSHIP',
    payload: membership,
  });
};

export const updateMembershipDiscounts = (membership, item, type) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_MEMBERSHIP_PAGE_UPDATE_DISCOUNTS',
    payload: { membership, item, type }
  });
};
