const INITIAL_STATE = {
  feedItems: [],
  feedItemsLoaded: false,
  newsItems: [],
  newsItemsLoaded: false,
  users: [],
  usersLoaded: false,
  inventory: [],
  inventoryLoaded: false,
  loaded: false,
  activeTab: 0,
  newsUnreadTally: 0,
  activityUnreadTally: 0,
  attendance: []
};

export const collectiveReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POPULATE_COLLECTIVE_FEED_ITEMS':
      return {
        ...state,
        feedItems: action.payload,
        feedItemsLoaded: true,
      };
    case 'POPULATE_COLLECTIVE_NEWS_ITEMS':
      return {
        ...state,
        newsItems: action.payload,
        newsItemsLoaded: true,
        newsUnreadTally: action.payload.filter(
                    (item) => item.read === false
        ).length,
      };
    case 'POPULATE_COLLECTIVE_USERS':
      return {
        ...state,
        users: action.payload,
        usersLoaded: true,
      };
    case 'POPULATE_COLLECTIVE_INVENTORY_ITEMS':
      return {
        ...state,
        inventory: action.payload,
        inventoryLoaded: true,
      };
    case 'CLEAR_COLLECTIVE_PAGE_DATA':
      return INITIAL_STATE;
    case 'INITIALIZE_COLLECTIVE_PAGE':
      return {
        ...state,
        loaded: true,
      };
    case 'CHANGE_COLLECTIVE_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'INCREMENT_COLLECTIVE_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload,
      };
    case 'DECREMENT_COLLECTIVE_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload,
      };
    case 'INCREMENT_COLLECTIVE_ACTIVITY_UNREAD_TALLY':
      return {
        ...state,
        activityUnreadTally: action.payload,
      };
    case 'CLEAR_COLLECTIVE_UNREAD_TALLY':
      return {
        ...state,
        // newsUnreadTally: 0,
        activityUnreadTally: 0,
      };
    case 'UPDATE_COLLECTIVE_PAGE_USERS_ATTENDANCE':
      return {
        ...state,
        attendance: action.payload
      };
    default:
      return state;
  }
};
