const INITIAL_STATE = {
  inventoryTypeFilter: '',
  selectedInventoryType: 0
};

export const inventoryTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_INVENTORY_TYPE_PAGE_FILTER_STRING':
      return {
        ...state,
        inventoryTypeFilter: action.payload
      };
    case 'UPDATE_ADMIN_INVENTORY_TYPE_PAGE_SELECTED_INVENTORY':
      return {
        ...state,
        selectedInventoryType: action.payload
      };
    default:
      return state;
  }
};
