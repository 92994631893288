import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { employeeAuth } from '../../../../apis/backend';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      skatepark: '',
      collective: '',
      events: '',
      coffee: '',
    };
  }

  async employeeRequest() {
    try {
      await employeeAuth
        .post('/signup', {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
        }, { headers: { authorization: this.props.employeeToken } })
        .then(() => {
          this.setState({
            firstName: '',
            lastName: '',
          });
        })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={ { overflow: 'scroll' } }>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    First Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.firstName }
                  placeholder="First Name"
                  onChange={ (e) => this.setState({ firstName: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Last Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.lastName }
                  placeholder="Last Name"
                  onChange={ (e) => this.setState({ lastName: e.target.value }) }
                />
              </div>
              <span className="input-group-text" id="inputGroup-sizing-sm">
                PIN will be generated automatically.
              </span>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.employeeRequest();
                } }
                disabled={
                  !(this.state.firstName !== '' && this.state.lastName !== '')
                }
              >
                Create Employee
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {})(AddModal);
