export const updateSystemUsersFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SYSTEM_USER_PAGE_FILTER_STRING',
    payload: filterString,
  });
};

export const updateSelectedSystemUser = (systemUser) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SYSTEM_USER_PAGE_SELECTED_SYSTEM_USER',
    payload: systemUser,
  });
};
