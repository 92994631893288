export const types = {
  adminPageInitialization: 'ADMIN_PAGE_INITIALIZATION',
  clearAdminPage: 'CLEAR_ADMIN_PAGE_STATE',
  populateAdminUsers: 'POPULATE_ADMIN_USERS',
  populateAdminRaspberryPi: 'POPULATE_ADMIN_RASPBERRY_PI',
  populateAdminInventory: 'POPULATE_ADMIN_INVENTORY_ITEMS',
  populateAdminInventoryTypes: 'POPULATE_ADMIN_INVENTORY_TYPE_ITEMS',
  populateAdminMemberships: 'POPULATE_ADMIN_MEMBERSHIPS',
  populateAdminEvents: 'POPULATE_ADMIN_EVENTS',
  populateAdminNews: 'POPULATE_ADMIN_NEWSLOG',
  populateAdminSkatePunches: 'POPULATE_ADMIN_SKATEPUNCHES',
  populateAdminEmployees: 'POPULATE_ADMIN_EMPLOYEES',
  populateAdminSystemLocations: 'POPULATE_ADMIN_SYSTEM_LOCATIONS',
  populateAdminSystemUsers: 'POPULATE_ADMIN_SYSTEM_USERS',
  populateAdminPurchaseLogs: 'POPULATE_ADMIN_PURCHASE_LOGS',
  populateAdminAccess: 'POPULATE_ADMIN_ACCESS',
  populateAdminAttendanceLogRecent: 'POPULATE_ADMIN_ATTENDANCELOG_RECENT',
  populateAdminMealSwipesAllowance: 'POPULATE_ADMIN_MEAL_SWIPES_ALLOWANCE',
  populateAdminRentals: 'POPULATE_ADMIN_RENTALS',
  populateAdminUserNotes: 'POPULATE_ADMIN_USER_NOTES',
  populateAdminSysEmpAccess: 'POPULATE_SYSTEM_EMPLOYEE_ACCESS',
  populateAdminNoteFlags: 'POPULATE_ADMIN_NOTE_FLAGS',
};
