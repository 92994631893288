import React, { Component } from 'react';
import './../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedUser } from '../../../../actions/adminActions/usersActions';
import { attendancelog } from '../../../../apis/backend.js';

class AttendanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAttendanceLogs: [],
    };
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    return `${ date.toDateString() }, ${ date.toLocaleTimeString() }`;
  }

  getLocation(locationId) {
    const location = this.props.systemLocations[ locationId - 1 ].location;
    return `${ location.charAt(0).toUpperCase() }${ location.slice(1) }`;
  }

  renderAttendance() {
    return (
      <>
        {this.state.userAttendanceLogs
          .map((log) => {
            return (
              <tr key={ log.id }>
                <td className="text-center">{this.getLocation(log.location)}</td>
                <td className="text-center">{this.formatDate(log.checkedInAt)}</td>
              </tr>
            );
          })}
      </>
    );
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (prevProps.selectedUser !== this.props.selectedUser && this.props.selectedUser !== 0) {
      this.getAttendanceLogByUserId();
    }
    if (prevProps.selectedUser !== this.props.selectedUser && this.props.selectedUser === 0) {
      this.setState({ userAttendanceLogs: [] });
    }
  }

  async getAttendanceLogByUserId() {
    await attendancelog
      .get(`/find/${ this.props.selectedUser }`, { headers: { authorization: this.props.employeeToken } })
      .then((res) => {
        this.setState({ userAttendanceLogs: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div
        id="attendanceModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Attendance History</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ () => this.props.updateSelectedUser(0) }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table
                className="table table-striped table-bordered table-hover bg-white mr-3"
                id="tab_logic"
              >
                <thead className=" bg-dark thead-dark">
                  <tr>
                    <th>Location</th>
                    <th>Check-In Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderAttendance()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedUser: state.admin.users.selectedUser,
  systemLocations: state.admin.admin.systemLocations,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedUser })(
  AttendanceModal
);
