const INITIAL_STATE = {
  name: '',
  selected: 0,
  filter: '',
};

export const eventsSelectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_EVENTS_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'UPDATE_CURRENT_EVENTS_SELECTED':
      return {
        ...state,
        selected: action.payload,
      };
    case 'CLEAR_CURRENT_EVENTS_SELECTED':
      return {
        ...state,
        name: '',
        selected: 0,
        filter: '',
      };
    case 'UPDATE_CURRENT_EVENTS_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};
