import React from 'react';
import { connect } from 'react-redux';
import './modal.css';
import { attendancelog } from '../../../apis/backend';

class CheckinAfterPurchase extends React.Component {
  async attendanceRequest(id) {
    await attendancelog.post('/user',
      {
        id,
        location: this.props.locationId,
        skateparkCheckInModal: true,
      },
      { headers: { authorization: this.props.employeeToken } }
    )
    .catch((err) => console.log(err));
  }

  render() {
    return (
      <>
        <div className={this.props.visible ? 'popup-container' : 'popup-container hide-content'}>
          <div id="content-spacer">
            <div className="popup-content">
              <div className="modal-text">
                Would you like to check in<br /><strong>{ this.props.name }</strong>?
              </div>
              <div className="popup-buttons">
                <button
                  className='popup-button'
                  onClick={ () => {
                    this.props.clearModal();
                  }}
                >
                  No
                </button>
                <button
                  className='popup-button'
                  onClick={ () => {
                    this.attendanceRequest(this.props.id);
                    this.props.clearModal();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationId: state.auth.sysLocationId,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps)(CheckinAfterPurchase);
