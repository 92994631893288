import React, { Component } from 'react';
import './../../Modal.css';
import { usernotes } from '../../../../apis/backend';
import { getContrastColor } from '../../../../apis/colors';
import { connect } from 'react-redux';
import { updateSelectedUser, updateSelectedNote } from '../../../../actions/adminActions/usersActions';

class EditNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      flagStatus: '',
    };
  }

  async notesUpdateRequest() {
    await usernotes
      .patch(
        `/${ this.props.selectedNote }`,
        {
          note: this.state.note,
          flagStatus: this.state.flagStatus,
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => {
        console.log(response);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedNote !== this.props.selectedNote &&
      this.props.selectedNote !== 0
    ) {
      const note = this.props.userNotes.find(
        (obj) => obj.id === this.props.selectedNote
      );
      if (note) {
        this.setState({
          note: note.note,
          flagStatus: note.flagStatus,
        });
      }
    }
  }

  noteFlagCheck() {
    if (this.props.selectedNote === 0) {
      return(<></>);
    }
    const note = this.props.userNotes.find(
      (obj) => obj.id === this.props.selectedNote
    );
    const flag = this.props.noteFlags.find(
      (obj) => obj.id === note.flag
    );
    if (note.flagStatus !== 'none') {
      let statusText = '';
      switch (note.flagStatus) {
        case 'cleared':
          statusText = 'Cleared';
          break;
        case 'notCleared':
          statusText = 'Not Cleared';
          break;
        case 'autoClears':
          statusText = 'Auto Clears';
          break;
        default:
          break;
      }
      return(
        <>
          <div className="mb-3" style={{ height: '21px' }}>
            <div style={{ float: 'left', width: '35%', margin: '0 7.5%' }}>
              Flag:
              <strong className='note-flag-style' style={{ backgroundColor: `${ flag.color }`, color: getContrastColor(flag.color) }}>
                {flag.name}
              </strong>
            </div>
            <div style={{ float: 'left', width: '35%', margin: '0 7.5%' }}>
              Status: <strong> {statusText} </strong>
            </div>
          </div>
          {note.flagStatus === 'autoClears' ?
            <></> :
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Change Status
                </span>
              </div>
              <select
                className="form-control"
                style={{ maxHeight: '70px', overflowY: 'auto' }}
                value={this.state.flagStatus}
                onChange={(e) => this.setState({ flagStatus: e.target.value })}
              >
                <option value={'notCleared'}>Not Cleared</option>
                <option value={'cleared'}>Cleared</option>
              </select>
            </div>
          }
        </>
      );
    }
  }

  render() {
    const user = this.props.users.find(
      (obj) => obj.id === this.props.selectedUser
    );
    const note = this.props.userNotes.find(
      (obj) => obj.id === this.props.selectedNote
    );
    return (
      <div className="modal fade" id="editNotesModal">
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit {user?.firstName}&apos;s Note</h5>
              <button
                type="button"
                data-target="#notesModal"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.note}
                  placeholder="Enter notes here..."
                  onChange={(e) =>
                    this.setState({ note: e.target.value })
                  }
                />
              </div>
              {this.noteFlagCheck()}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.notesUpdateRequest();
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
                disabled={!(this.state.note !== '')}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  userNotes: state.admin.admin.userNotes,
  noteFlags: state.admin.admin.noteFlags,
  selectedUser: state.admin.users.selectedUser,
  selectedNote: state.admin.users.selectedNote,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedUser, updateSelectedNote })(EditNotesModal);
