const INITIAL_STATE = {
  purchaseOptions: [],
  cart: [],
  purchaseItemFilter: '',
  loaded: false
};

export const skateparkPurchaseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIALIZE_SKATEPARK_PURCHASE_PAGE': {
      return {
        ...state,
        purchaseOptions: action.payload,
        loaded: true
      };
    }
    case 'SKATEPARK_PURCHASE_PAGE_UPDATE_CART':
      return {
        ...state,
        cart: action.payload
      };
    case 'SKATEPARK_PURCHASE_PAGE_UPDATE_FILTER_STRING':
      return {
        ...state,
        purchaseItemFilter: action.payload
      };
    case 'CLEAR_SKATEPARK_PURCHASE_PAGE':
      return INITIAL_STATE;
    default:
      return state;
  }
};
