export const initializePurchasePage = response => (dispatch) => {
  dispatch({ type: 'INITIALIZE_SKATEPARK_PURCHASE_PAGE', payload: response });
};

export const addToCart = id => (dispatch, getState) => {
  const currentCart = [ ...getState().skatepark.purchase.cart ];
  if (!currentCart.includes(id)) {
    currentCart.push(id);
    dispatch({
      type: 'SKATEPARK_PURCHASE_PAGE_UPDATE_CART',
      payload: currentCart
    });
  }
};

export const removeFromCart = id => (dispatch, getState) => {
  const currentCart = [ ...getState().skatepark.purchase.cart ];
  if (currentCart.includes(id)) {
    dispatch({
      type: 'SKATEPARK_PURCHASE_PAGE_UPDATE_CART',
      payload: currentCart.filter(item => item !== id)
    });
  }
};

export const updatePurchaseItemFilter = filter => (dispatch) => {
  dispatch({
    type: 'SKATEPARK_PURCHASE_PAGE_UPDATE_FILTER_STRING',
    payload: filter
  });
};

export const clearPurchasePage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_SKATEPARK_PURCHASE_PAGE' });
};
