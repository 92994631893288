import React from 'react';
import './coffee.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './home/home';
import Checkin from './checkin/checkin';
import Loading from '../loading/loading';
import NotFound from '../404/404';
import Login from '../login/login.jsx';
import {
  populateCoffeeFeed,
  updateCoffeeFeedItems,
  populateCoffeeUsers,
  createCoffeeUser,
  updateCoffeeUser,
  deleteCoffeeUser,
  clearCoffeePage,
  initializeCoffee
} from '../../actions/coffeePageActions/coffeeActions';
import { attendancelog } from '../../apis/backend';

class Coffee extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/activitylog/recent', (resData) => {
        this.props.populateCoffeeFeed(resData);
      });
      await this.props.io.get('/users', (resData) => {
        this.props.populateCoffeeUsers(resData);
      });

      this.props.io.on('usercreate', (event) => {
        this.props.createCoffeeUser(event);
      });
      this.props.io.on('userupdate', (event) => {
        this.props.updateCoffeeUser(event);
      });
      this.props.io.on('userdelete', (event) => {
        this.props.deleteCoffeeUser(event);
      });
      this.props.io.on('activitylogcreate', (event) => {
        this.props.updateCoffeeFeedItems(event);
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loaded &&
            this.props.usersLoaded &&
            this.props.feedItemsLoaded
    ) {
      this.props.initializeCoffee();
    }
  }

  componentWillUnmount() {
    try {
      this.props.io.off('activitylogcreate');
      this.props.io.off('userdelete');
      this.props.io.off('userupdate');
      this.props.io.off('usercreate');
      // this.props.clearCoffeePage();
    } catch (err) {
      console.log(err);
    }
  }

  async attendanceRequest(id) {
    await attendancelog.post('/user', {
      id,
      location: this.props.locationId
    }, { headers: { authorization: this.props.employeeToken } }).catch((err) => console.log(err) );
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Router>
        <Switch>
          <Route
            path='/coffee/checkin'
            render={ (props) => (
              <Checkin
                { ...props }
                button={ {
                  title: 'Check-In',
                  clickEvent: (e) =>
                    this.attendanceRequest(e),
                  redirect: '/coffee',
                } }
              ></Checkin>
            ) }
          />
          <Route exact path='/coffee' component={ HomePage } />
          <Route exact path='/' component={ Login } />
          <Route component={ NotFound } />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  connected: state.application.connected,
  loaded: state.coffee.coffee.loaded,
  feedItemsLoaded: state.coffee.coffee.feedItemsLoaded,
  usersLoaded: state.coffee.coffee.usersLoaded,
  locationId: state.auth.sysLocationId,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  populateCoffeeFeed,
  updateCoffeeFeedItems,
  populateCoffeeUsers,
  createCoffeeUser,
  updateCoffeeUser,
  deleteCoffeeUser,
  clearCoffeePage,
  initializeCoffee
})(Coffee);
