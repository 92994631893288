import React, { Component } from 'react';
import './../../Modal.css';
import { connect } from 'react-redux';
import {
  updateAdminObject,
} from '../../../../actions/adminActions/adminActions';
import { types } from '../../../../actions/actionTypes';
import { usersAccess, usersMemberships } from '../../../../apis/backend';

class BulkActionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFilter: '',
      resultText: '',
      selectedAction: 0,
      selectedCategory: 0,
      selectedField: 0,
      processing: false,
      selectAll: false,
    };
  }

  componentDidUpdate() {
    if (this.state.selectAll !== this.props.users.every(user => user.bulkActionToggle)) {
      this.setState({ selectAll: this.props.users.every(user => user.bulkActionToggle) });
    }
  }

  handleChange = (e) => {
    const boxName = e.target.name;
    const checked = e.target.checked;
    if (boxName === 'checkAll') {
      // Set all user flags to the same value as 'checked'
      this.setState({ selectAll: checked });
      if (this.props.users.length > 0) {
        const updatedUsers = this.props.users;
        for (const user of updatedUsers) {
          user.bulkActionToggle = checked;
        }
        this.props.updateAdminObject(updatedUsers, types.populateAdminUsers, 'users');
      }
    } else {
      // Update only one user flag and set `selectAll` based on all user flags
      if (this.props.users.length > 0) {
        const updatedUser = this.props.users.filter((user) => {
          return user.id === parseInt(boxName);
        });
        updatedUser[ 0 ].bulkActionToggle = checked;
        this.props.updateAdminObject(updatedUser, types.populateAdminUsers, 'users');
        this.setState({ selectAll: this.props.users.every(user => user.bulkActionToggle) });
      }
    }
  };

  renderUsers() {
    //renders the users table
    if (this.props.users.length > 0) {
      return (
        <>
          {this.props.users
            .filter((user) => {
              const filterString = `${ user.firstName } ${ user.lastName } ${ user.email } ${ user.phoneNumber }`;
              return (
                filterString
                  .toLowerCase()
                  .indexOf(this.state.userFilter.toLowerCase()) > -1
              );
            })
            .map((user) => (
              <tr key={this.props.users.indexOf(user)}>
                <td className="text-center" style={{ width: '11.5%' }}>
                  <input
                    name={user.id}
                    type="checkbox"
                    checked={user.bulkActionToggle}
                    onChange={this.handleChange}
                  />
                </td>
                <td className="text-center" style={{ width: '28%' }}>
                  {`${ user.firstName } ${ user.lastName }`}
                </td>
                <td className="text-center" style={{ width: '33.5%' }}>
                  {user.email}
                </td>
                <td className="text-center " style={{ width: '27%' }}>
                  {user.phoneNumber}
                </td>
              </tr>
            ))}
        </>
      );
    }
    return (
      <></>
    );
  }

  renderButtonText() {
    if (this.state.processing) {
      return (
        <>
          <div className="loading-indicator" id="loading">
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </>
      );
    } else {
      return 'Apply';
    }
  }

  getFieldOptions() {
    if (this.state.selectedCategory === 1) {
      // Access category selected
      return(
        <>
          {this.props.access
            .filter((acc) => acc.price === 0)
            .map((acc) => (
              <option
                key={acc.name}
                value={acc.id}
              >
                {acc.name}
              </option>
            ))}
        </>
      );
    } else if (this.state.selectedCategory === 2) {
      // Memberships category selected
      return(
        <>
          {this.props.memberships
            .filter((membership) => membership.price === 0)
            .map((membership) => (
              <option
                key={membership.accessName}
                value={membership.id}
              >
                {membership.accessName}
              </option>
            ))}
        </>
      );
    }
  }

  async submitBulkAction() {
    this.setState({ processing: true });
    const current = new Date();
    let total = 0;

    if (this.state.selectedCategory === 1) {
      // Access actions
      const accessType = this.props.access.find(
        (obj) => obj.id === this.state.selectedField
      );

      for (const user of this.props.users) {
        const userAccess = await usersAccess
          .get('/find', {
            headers: { authorization: this.props.employeeToken },
            params: {
              user: user.id,
              access: accessType.id,
            },
          })
          .catch((response) => {
            console.log(response);
          });

        if (this.state.selectedAction === 1 && user.bulkActionToggle) {
          // Add access
          if (!userAccess.data.length > 0 || new Date(userAccess.data[ 0 ].endTime).getTime() < current.getTime()) {
            // Access doesn't exist or is expired: create new one
            await usersAccess.post('/',
              {
                user: user.id,
                access: accessType.id,
              },
              { headers: { authorization: this.props.employeeToken } }
            ).catch((err) => console.log(err));
            total++;
          }
        } else if (this.state.selectedAction === 2 && user.bulkActionToggle && userAccess.data.length > 0) {
          // Remove access
          if (new Date(userAccess.data[ 0 ].endTime).getTime() > current.getTime()) {
            // Expire existing access
            await usersAccess
              .patch(
                `/expire/${ userAccess.data[ 0 ].id }`,
                {},
                { headers: { authorization: this.props.employeeToken } }
              )
              .catch((err) => console.log(err));
            total++;
          }
        }
      }
    } else if (this.state.selectedCategory === 2) {
      // Membership actions
      const membershipType = this.props.memberships.find(
        (obj) => obj.id === this.state.selectedField
      );

      for (const user of this.props.users) {
        const userMemberships = await usersMemberships
          .get('/find', {
            headers: { authorization: this.props.employeeToken },
            params: {
              user: user.id,
              membership: membershipType.id,
            },
          })
          .catch((response) => {
            console.log(response);
          });

        if (this.state.selectedAction === 1 && user.bulkActionToggle) {
          // Add membership
          if (!userMemberships.data.length > 0 || new Date(userMemberships.data[ 0 ].endTime).getTime() < current.getTime()) {
            // Membership doesn't exist or has expired: create new one
            await usersMemberships.post('/',
              {
                user: user.id,
                membership: membershipType.id,
              },
              { headers: { authorization: this.props.employeeToken } }
            ).catch((err) => console.log(err));
            total++;
          }
        } else if (this.state.selectedAction === 2 && user.bulkActionToggle && userMemberships.data.length > 0) {
          // Remove membership
          if (new Date(userMemberships.data[ 0 ].endTime).getTime() > current.getTime()) {
            // Expire existing membership
            await usersMemberships
              .patch(
                `/expire/${ userMemberships.data[ 0 ].id }`,
                {},
                { headers: { authorization: this.props.employeeToken } }
              )
              .catch((err) => console.log(err));
            total++;
          }
        }
      }
    }
    this.setState({ resultText: `Users Affected: ${ total }`, processing: false });
    // setTimeout(() => {
    //   this.setState({ processing: false });
    // }, 5000);
  }

  resetState() {
    this.setState({
      userFilter: '',
      resultText: '',
      selectedAction: 0,
      selectedCategory: 0,
      selectedField: 0,
      processing: false,
      selectAll: false,
    });
    const resetUsers = this.props.users;
    for (const user of resetUsers) {
      user.bulkActionToggle = false;
    }
    this.props.updateAdminObject(resetUsers, types.populateAdminUsers, 'users');
  }

  render() {
    return (
      <div id="bulkActionsModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg" style={{ maxWidth: '800px' }}>
          <div className="modal-content" style={{ height: '630px' }}>
            <div className="modal-header">
              <h5 className="modal-title">User Bulk Actions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.resetState();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className='modal-body' style={{ padding: '0' }}>
              <form className='form-inline md-form form-sm' style={{ margin: '10px' }}>
                <div className="input-group input-group-sm" style={{ width: '32%' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Actions
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.selectedAction}
                    onChange={(e) =>
                      this.setState({ selectedAction: parseInt(e.target.value) })
                    }
                  >
                    <option value={0} disabled>Select an Action...</option>
                    <option value={1}>Add</option>
                    <option value={2}>Remove</option>
                  </select>
                </div>
                <div className="input-group input-group-sm" style={{ width: '32%', margin: '0 auto' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Categories
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.selectedCategory}
                    onChange={(e) =>
                      this.setState({
                        selectedCategory: parseInt(e.target.value),
                        selectedField: 0,
                      })
                    }
                  >
                    <option value={0} disabled>Select a Category...</option>
                    <option value={1}>Access</option>
                    <option value={2}>Memberships</option>
                  </select>
                </div>
                <div className="input-group input-group-sm" style={{ width: '32%' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Options
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.selectedField}
                    onChange={(e) =>
                      this.setState({ selectedField: parseInt(e.target.value) })
                    }
                  >
                    <option value={0} disabled>Select an Option...</option>
                    {this.getFieldOptions()}
                  </select>
                </div>
              </form>
              <form className='form-inline md-form form-sm' style={{ margin: '10px 10px 0' }}>
                <div className="input-group input-group-sm" style={{ width: '35%', marginRight: '10px' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      User Search
                    </span>
                  </div>
                  <input
                    value={this.state.userFilter}
                    className="form-control"
                    style={{ width: '256px' }}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => {
                      this.setState({ userFilter: e.target.value });
                    }}
                  />
                </div>
                <div style={{ textAlign: 'center', width: '63.5%' }}>
                  <strong>{this.state.resultText}</strong>
                </div>
              </form>
              <div
                className="table-wrapper-scroll-y"
                style={{ margin: '10px 0', height: '400px', overflowX: 'hidden', overflowY: 'scroll' }}
              >
                <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
                  <thead className="bg-dark thead-dark">
                    <tr>
                      <th className="text-center" style={{ width: '11.5%' }}>
                        <input
                          name="checkAll"
                          type="checkbox"
                          checked={this.state.selectAll}
                          onChange={this.handleChange}
                        /> Select
                      </th>
                      <th className="text-center" style={{ width: '28%' }}>
                        Name
                      </th>
                      <th className="text-center" style={{ width: '33.5%' }}>
                        Email
                      </th>
                      <th className="text-center" style={{ width: '27%' }}>
                        PhoneNumber
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderUsers()}</tbody>
                </table>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  this.resetState();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                style={{ width: '77.75px', height: '38px' }}
                onClick={() => {
                  this.submitBulkAction();
                }}
                disabled={(
                  this.state.processing ||
                  this.state.selectedField === 0 ||
                  this.state.selectedAction === 0
                )}
              >
                {this.renderButtonText()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  access: state.admin.admin.access,
  memberships: state.admin.admin.memberships,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateAdminObject })(BulkActionsModal);
