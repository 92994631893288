const INITIAL_STATE = {
  feedItems: [],
  feedItemsLoaded: false,
  newsItems: [],
  newsItemsLoaded: false,
  users: [],
  usersLoaded: false,
  events: [],
  selectedEventId: 0,
  eventsLoaded: false,
  loaded: false,
  activeTab: 0,
  newsUnreadTally: 0,
  activityUnreadTally: 0,
  ticketAttendanceInterval: null,
  cashAttendanceInterval: null,
};

export const eventsPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POPULATE_EVENTS_FEED_ITEMS':
      return {
        ...state,
        feedItems: action.payload,
        feedItemsLoaded: true,
      };
    case 'POPULATE_EVENTS_NEWS_ITEMS':
      return {
        ...state,
        newsItems: action.payload,
        newsItemsLoaded: true,
        newsUnreadTally: action.payload.filter(
                    (item) => item.read === false
        ).length,
      };
    case 'POPULATE_EVENTS_USERS':
      return {
        ...state,
        users: action.payload,
        usersLoaded: true,
      };
    case 'CLEAR_EVENTS_PAGE_DATA':
      return INITIAL_STATE;
    case 'INITIALIZE_EVENTS_PAGE':
      return {
        ...state,
        loaded: true,
      };
    case 'CHANGE_EVENTS_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'INCREMENT_EVENTS_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload,
      };
    case 'DECREMENT_EVENTS_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload,
      };
    case 'INCREMENT_EVENTS_ACTIVITY_UNREAD_TALLY':
      return {
        ...state,
        activityUnreadTally: action.payload,
      };
    case 'CLEAR_EVENTS_UNREAD_TALLY':
      return {
        ...state,
        // newsUnreadTally: 0,
        activityUnreadTally: 0,
      };
    case 'POPULATE_EVENTS_PAGE_EVENTS':
      return {
        ...state,
        events: action.payload,
        eventsLoaded: true,
      };
    case 'GET_CURRENT_EVENT':
      return {
        ...state,
        selectedEventId: action.payload,
      };
    case 'UPDATE_EVENTS_PAGE_TICKET_INTERVAL':
      return {
        ...state,
        ticketAttendanceInterval: action.payload
      };
    case 'UPDATE_EVENTS_PAGE_CASH_INTERVAL':
      return {
        ...state,
        cashAttendanceInterval: action.payload
      };
    default:
      return state;
  }
};
