import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { employeeAuth } from '../apis/backend';
import { updateEmpUser, updateAuth } from '../actions/authActions';

class LoggedInRoute extends React.Component {
  async checkUnlocked() {
    await employeeAuth.get('/', { headers: { authorization: this.props.employeeToken } })
      .then(res => {
        this.props.updateEmpUser(res.data);
      })
      .catch(err => {
        console.log(err);
        this.props.updateAuth({ systemToken: this.props.systemToken, employeeToken: null });
      });
  }

  render() {
    if(this.props.isLoggedIn) {
      if(this.props.isUnlocked) {
        return <Redirect to={ `/${ this.props.sysLocation }` } />;
      }
      if(this.props.employeeToken) {
        this.checkUnlocked();
      }
      return <Route component={ this.props.component } />;
    }
    return <Redirect to={ '/login' }/>;
  }
}

const mapStateToProps = (state) =>({
  isLoggedIn: state.auth.isLoggedIn,
  isUnlocked: state.auth.isUnlocked,
  employeeToken: state.auth.employeeToken,
  sysLocation: state.auth.sysLocation,
  systemToken: state.auth.systemToken
});

export default connect(mapStateToProps, { updateEmpUser, updateAuth })(LoggedInRoute);
