import React from 'react';
import Page from '../utils/page/page.jsx';
import './404.css';
import funny from '../../images/skateboard_fail.gif';

class NotFound extends React.Component {
  render() {
    const names = [ 'Joey', 'Easton', 'Avinash', 'Micah', 'Aaron', 'Sarah' ];
    const name = names[ Math.floor(Math.random() * names.length) ];
    return (
      <Page>
        <div className="not-found">
          <h1>404 not found</h1>
          <p>
            Oops! Something went wrong! It's probably {name}'s
            fault...
          </p>
          <div>
            <img src={ funny } alt="404"></img>
          </div>
        </div>
      </Page>
    );
  }
}

export default NotFound;
