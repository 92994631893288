export const populateSkateparkFeed = (feedItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_FEED_ITEMS',
    // payload: feedItems.filter((item) => item.location === 'skatepark')
    payload: feedItems,
  });
};

export const populateSkateparkNews = (newsItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_NEWS_ITEMS',
    payload: newsItems,
  });
};

export const updateSkateparkFeedItems = (feedItem) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_FEED_ITEMS',
    payload: [ feedItem, ...getState().skatepark.skatepark.feedItems ],
  });
};

export const updateSkateparkNewsItems = (newsItem) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_NEWS_ITEMS',
    payload: [ newsItem, ...getState().skatepark.skatepark.newsItems ],
  });
};

export const populateSkateparkUsers = (users) => (dispatch) => {
  dispatch({ type: 'POPULATE_SKATEPARK_USERS', payload: users });
};

export const createSkateparkUser = (user) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_USERS',
    payload: [ user, ...getState().skatepark.skatepark.users ],
  });
};

export const updateSkateparkUser = (updatedUsers) => async (
  dispatch,
  getState
) => {
  const users = [ ...getState().skatepark.skatepark.users ];
  updatedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users[ users.indexOf(oldUser) ] = user;
  });
  dispatch({
    type: 'POPULATE_SKATEPARK_USERS',
    payload: users,
  });
};

export const deleteSkateparkUser = (deletedUsers) => async (
  dispatch,
  getState
) => {
  const users = [ ...getState().skatepark.skatepark.users ];
  deletedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users.splice(users.indexOf(oldUser), 1);
  });
  dispatch({
    type: 'POPULATE_SKATEPARK_USERS',
    payload: users,
  });
};

export const populateSkateparkInventory = (inventory) => (dispatch) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_INVENTORY_ITEMS',
    payload: inventory,
  });
};

export const createSkateparkInventory = (inventory) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_INVENTORY_ITEMS',
    payload: [ inventory, ...getState().skatepark.skatepark.inventory ],
  });
};

export const updateSkateparkInventory = (updatedInventory) => async (
  dispatch,
  getState
) => {
  const inventorys = [ ...getState().skatepark.skatepark.inventory ];
  const oldInventory = inventorys.find((obj) => obj.id === updatedInventory.id);
  inventorys[ inventorys.indexOf(oldInventory) ] = updatedInventory;
  dispatch({
    type: 'POPULATE_SKATEPARK_INVENTORY_ITEMS',
    payload: inventorys,
  });
};

export const deleteSkateparkInventory = (deletedInventorys) => async (
  dispatch,
  getState
) => {
  const inventorys = [ ...getState().skatepark.skatepark.inventory ];
  deletedInventorys.forEach((inventory) => {
    const oldInventory = inventorys.find((obj) => obj.id === inventory.id);
    inventorys.splice(inventorys.indexOf(oldInventory), 1);
  });
  dispatch({
    type: 'POPULATE_SKATEPARK_INVENTORY_ITEMS',
    payload: inventorys,
  });
};

export const clearSkateparkPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_SKATEPARK_PAGE_DATA' });
};

export const initializeSkatepark = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_SKATEPARK_PAGE' });
};

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({
    type: 'CHANGE_SKATEPARK_ACTIVE_TAB',
    payload: index,
  });
};

export const incrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_SKATEPARK_NEWS_UNREAD_TALLY',
    payload: getState().skatepark.skatepark.newsUnreadTally + 1,
  });
};

export const decrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'DECREMENT_SKATEPARK_NEWS_UNREAD_TALLY',
    payload: getState().skatepark.skatepark.newsUnreadTally - 1,
  });
};

export const incrementActivityUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_SKATEPARK_ACTIVITY_UNREAD_TALLY',
    payload: getState().skatepark.skatepark.activityUnreadTally + 1,
  });
};

export const clearUnreadTally = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SKATEPARK_UNREAD_TALLY',
    payload: 0,
  });
};

export const updateSkateparkAttendance = (attendance) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_PAGE_USERS_ATTENDANCE',
    payload: attendance,
  });
};

export const populateSkateparkUserNotes = (notes) => (dispatch) => {
  dispatch({ type: 'POPULATE_SKATEPARK_USER_NOTES', payload: notes });
};

export const createSkateparkUserNote = (note) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_SKATEPARK_USER_NOTES',
    payload: [ note, ...getState().skatepark.skatepark.userNotes ],
  });
};

export const updateSkateparkUserNote = (updatedNotes) => async (
  dispatch,
  getState
) => {
  const notes = [ ...getState().skatepark.skatepark.userNotes ];
  updatedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes[ notes.indexOf(oldNote) ] = note;
  });
  dispatch({
    type: 'POPULATE_SKATEPARK_USER_NOTES',
    payload: notes,
  });
};

export const deleteSkateparkUserNote = (deletedNotes) => async (
  dispatch,
  getState
) => {
  const notes = [ ...getState().skatepark.skatepark.userNotes ];
  deletedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes.splice(notes.indexOf(oldNote), 1);
  });
  dispatch({
    type: 'POPULATE_SKATEPARK_USER_NOTES',
    payload: notes,
  });
};
