import React from 'react';
import Page from '../utils/page/page.jsx';
import './loading.css';
import Error from '../../images/broken-board-transparent-white.png';

class Loading extends React.Component {
  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      document.getElementById('loading').style.display = 'none';
      document.getElementById('error').style.display = 'flex';
    }, 10000);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      <Page>
        <div className="loading-indicator" id="loading">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <div className="error-indicator" id="error" onClick={ () => window.location.reload(false) } >
          <div>Something went wrong...</div>
          <img style={ { maxWidth: '200px' } } src={ Error } alt="Broken Board"/>
          <div>Tap to reload!</div>
        </div>
      </Page>
    );
  }
}

export default Loading;
