import React from 'react';
import Users from './users/users.jsx';
import Inventory from './inventory/inventory.jsx';
import InventoryType from './inventoryTypes/inventoryTypes.jsx';
import Home from './home/home.jsx';
import Memberships from './memberships/memberships.jsx';
import MealSwipesAllowance from './mealSwipes/mealSwipes.jsx';
import SkatePunches from './skatePunches/skatePunches.jsx';
import Employees from './employees/employees.jsx';
import SystemUsers from './systemUsers/systemUsers.jsx';
import Events from './events/events.jsx';
import News from './news/news.jsx';
import Scanners from './scanners/scanners';
import PurchaseHistory from './purchaseHistory/purchaseHistory.jsx';
import Access from './access/access.jsx';
import Reporting from './reporting/reporting.jsx';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminLayout from './adminLayout/adminLayout';
import Loading from '../loading/loading';
import NotFound from '../404/404';
import {
  initializeAdminPage,
  clearAdminPage,
  populateAdminObjects,
  createAdminObject,
  updateAdminObject,
  deleteAdminObject,
} from '../../actions/adminActions/adminActions';
import { types } from '../../actions/actionTypes';
// import { smartwaiver } from '../../apis/backend';

import './admin.css';

const socketList = [
  [ 'user', types.populateAdminUsers, 'users' ],
  [ 'usernotes', types.populateAdminUserNotes, 'userNotes' ],
  [ 'noteflags', types.populateAdminNoteFlags, 'noteFlags' ],
  [ 'raspberrypi', types.populateAdminRaspberryPi, 'raspberryPis' ],
  [ 'inventory', types.populateAdminInventory, 'inventory' ],
  [ 'inventorytype', types.populateAdminInventoryTypes, 'inventoryTypes' ],
  [ 'membership', types.populateAdminMemberships, 'memberships' ],
  [ 'employees', types.populateAdminEmployees, 'employees' ],
  [ 'event', types.populateAdminEvents, 'events' ],
  [ 'newslog', types.populateAdminNews, 'news' ],
  [ 'skatePunches', types.populateAdminSkatePunches, 'skatePunches' ],
  [ 'access', types.populateAdminAccess, 'access' ],
  [ 'mealswipesallowance', types.populateAdminMealSwipesAllowance, 'mealSwipesAllowance' ],
  [ 'systemusers', types.populateAdminSystemUsers, 'systemUsers' ],
  [ 'rentals', types.populateAdminRentals, 'rentals' ],
];

class Admin extends React.Component {
  async componentDidMount() {
    try {
      // await smartwaiver
      // .post('/')
      // .catch((response) => console.log(response));
      // Users
      await this.props.io.get('/users', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminUsers);
      });

      // User Notes
      await this.props.io.get('/usernotes', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminUserNotes);
      });

      // Note Flags
      await this.props.io.get('/noteflags', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminNoteFlags);
      });

      // System Locations
      await this.props.io.get('/systemLocations', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminSystemLocations);
      });

      // Meal Swipe Allowance
      await this.props.io.get('/allowance', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminMealSwipesAllowance);
      });

      // Raspberry Pis
      await this.props.io.get('/raspberrypi', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminRaspberryPi);
      });

      // Inventory
      await this.props.io.get('/inventory', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminInventory);
      });

      // Inventory Types
      await this.props.io.get('/inventorytype', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminInventoryTypes);
      });

      // Memberships
      await this.props.io.get('/memberships', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminMemberships);
      });

      // Events
      await this.props.io.get('/events', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminEvents);
      });

      // News Logs
      await this.props.io.get('/newslog', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminNews);
      });

      // Skate Punches
      await this.props.io.get('/skatepunches', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminSkatePunches);
      });

      // Employee Users
      await this.props.io.get('/employeeuser/all', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminEmployees);
      });

      // System Users
      await this.props.io.get('/systemUser/all', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminSystemUsers);
      });

      // Attendance Logs
      await this.props.io.get('/attendancelog/recent', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminAttendanceLogRecent);
      });

      // Accesses
      await this.props.io.get('/access', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminAccess);
      });

      // Rentals
      await this.props.io.get('/rentals', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminRentals);
      });

      // Purchase Logs
      await this.props.io.get('/purchaselog/', (obj) => {
        this.props.populateAdminObjects(obj, types.populateAdminPurchaseLogs);
      });
      // Doesn't need update or delete since you cannot update or delete them
      this.props.io.on('purchaselogcreate', (obj) => {
        this.props.createAdminObject(obj, types.populateAdminPurchaseLogs, 'purchaseLogs');
      });

      // Enable websockets
      for(const socket of socketList) {
        const deleteSocket = socket[ 0 ] + 'delete';
        const updateSocket = socket[ 0 ] + 'update';
        const createSocket = socket[ 0 ] + 'create';
        this.props.io.on(updateSocket, (obj) => {
          Array.isArray(obj) ?
            this.props.updateAdminObject(obj, socket[ 1 ], socket[ 2 ]) :
            this.props.updateAdminObject([ obj ], socket[ 1 ], socket[ 2 ]);
        });
        this.props.io.on(createSocket, (obj) => {
          this.props.createAdminObject(obj, socket[ 1 ], socket[ 2 ]);
        });
        this.props.io.on(deleteSocket, (obj) => {
          this.props.deleteAdminObject(obj, socket[ 1 ], socket[ 2 ]);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.usersLoaded &&
      this.props.raspberryPisLoaded &&
      this.props.inventoryLoaded &&
      this.props.inventoryTypesLoaded &&
      this.props.membershipsLoaded &&
      this.props.eventsLoaded &&
      this.props.newsLoaded &&
      this.props.skatePunchesLoaded &&
      this.props.employeesLoaded &&
      this.props.employeesLoaded &&
      this.props.systemUsersLoaded &&
      this.props.purchaseLogsLoaded &&
      this.props.accessLoaded &&
      this.props.attendanceLogRecentLoaded &&
      this.props.rentalsLoaded &&
      this.props.userNotesLoaded &&
      this.props.noteFlagsLoaded &&
      !this.props.isLoaded
    ) {
      this.props.initializeAdminPage();
    }
  }

  componentWillUnmount() {
    this.props.clearAdminPage();

    // Disable websockets
    for(const socket of socketList) {
      const deleteSocket = socket[ 0 ] + 'delete';
      const updateSocket = socket[ 0 ] + 'update';
      const createSocket = socket[ 0 ] + 'create';
      this.props.io.off(deleteSocket);
      this.props.io.off(updateSocket);
      this.props.io.off(createSocket);
    }

    // Purchase Logs only have the create socket
    this.props.io.off('purchaselogcreate');
  }

  render() {
    if (!this.props.isLoaded) {
      return <Loading />;
    }
    return (
      <AdminLayout {...this.props}>
        <Switch>
          <Route exact path="/admin/home" component={Home} />
          <Route exact path="/admin/users" component={Users} />
          <Route exact path="/admin/inventory" component={Inventory} />
          <Route exact path="/admin/inventoryType" component={InventoryType} />
          <Route exact path="/admin/memberships" component={Memberships} />
          <Route
            exact
            path="/admin/coffeeShop"
            component={MealSwipesAllowance}
          />
          <Route exact path="/admin/skatePunches" component={SkatePunches} />
          <Route exact path="/admin/employees" component={Employees} />
          <Route exact path="/admin/systemusers" component={SystemUsers} />
          <Route exact path="/admin/events" component={Events} />
          <Route exact path="/admin/news" component={News} />
          <Route exact path="/admin/scanners" component={Scanners} />
          <Route
            exact
            path="/admin/purchasehistory"
            component={PurchaseHistory}
          />
          <Route exact path="/admin/access" component={Access} />
          <Route exact path="/admin/reporting" component={Reporting} />
          <Route exact path="/admin/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  isLoaded: state.admin.admin.isLoaded,
  usersLoaded: state.admin.admin.usersLoaded,
  raspberryPisLoaded: state.admin.admin.raspberryPisLoaded,
  inventoryLoaded: state.admin.admin.inventoryLoaded,
  inventoryTypesLoaded: state.admin.admin.inventoryTypesLoaded,
  membershipsLoaded: state.admin.admin.membershipsLoaded,
  eventsLoaded: state.admin.admin.eventsLoaded,
  newsLoaded: state.admin.admin.newsLoaded,
  skatePunchesLoaded: state.admin.admin.skatePunchesLoaded,
  employeesLoaded: state.admin.admin.employeesLoaded,
  systemUsersLoaded: state.admin.admin.employeesLoaded,
  purchaseLogsLoaded: state.admin.admin.purchaseLogsLoaded,
  accessLoaded: state.admin.admin.accessLoaded,
  attendanceLogRecentLoaded: state.admin.admin.attendanceLogRecentLoaded,
  rentalsLoaded: state.admin.admin.rentalsLoaded,
  newsLogEmployeeUsersLoaded: state.admin.admin.newsLogEmployeeUsersLoaded,
  userNotesLoaded: state.admin.admin.userNotesLoaded,
  noteFlagsLoaded: state.admin.admin.noteFlagsLoaded,
});

export default connect(mapStateToProps, {
  initializeAdminPage,
  clearAdminPage,
  populateAdminObjects,
  createAdminObject,
  updateAdminObject,
  deleteAdminObject,
})(Admin);
