export const clearRfidRegisterPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_SKATEPARK_REGISTER_RFID_PAGE' });
};

export const updateRfidScans = (rfidObject) => (dispatch, getState) => {
  const date = new Date();
  dispatch({
    type: 'SKATEPARK_UPDATE_REGISTER_RFID_SCANS',
    payload: [ ...getState().skatepark.rfidRegister.rfidScans, { rfid: rfidObject.rfid, date } ]
  });
};

export const updateRfidSelected = (selected) => (dispatch, getState) => {
  if(selected === getState().skatepark.rfidRegister.selected) {
    dispatch({ type: 'UPDATE_SKATEPARK_REGISTER_RFID_SELECTED', payload: -1 });
  } else {
    dispatch({ type: 'UPDATE_SKATEPARK_REGISTER_RFID_SELECTED', payload: selected });
  }

};
