import React from 'react';
import './events.css';
import { connect } from 'react-redux';
import {
  updateEventFilter,
  updateSelectedEvent,
} from '../../../actions/adminActions/eventsActions';
import { events } from '../../../apis/backend'; //make sure to include this for each page
import EditEventModal from './eventModals/editEventModal.jsx';
import UserAttendanceModal from './eventModals/userAttendanceModal.jsx';
import AddEventModal from './eventModals/addEventModal.jsx';

class Events extends React.Component {
  async eventDeleteRequest(id) {
    await events.delete(`/${ id }`, { headers: { authorization: this.props.employeeToken } }).catch((response) => {
      console.log(response);
    });
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    return `${ date.toDateString() }, ${ date.toLocaleTimeString() }`;
  }

  renderevents() {
    //renders the events table
    return (
      <>
        {this.props.events
          .filter((event) => {
            const filterString = `${ event.eventName } ${ this.formatDate(
              event.startTime
            ) } ${ this.formatDate(event.endTime) }`;
            return (
              filterString
                .toLowerCase()
                .indexOf(this.props.eventFilter.toLowerCase()) > -1
            );
          })
          .map((event) => (
            <tr key={ this.props.events.indexOf(event) }>
              <td className="text-center" style={ { width: '24%' } }>
                {`${ event.eventName }`}
              </td>
              <td className="text-center" style={ { width: '23%' } }>
                {this.formatDate(event.startTime)}
              </td>
              <td className="text-center " style={ { width: '23%' } }>
                {this.formatDate(event.endTime)}
              </td>
              <td className="text-center " style={ { width: '9%' } }>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedEvent(event.id);
                  } }
                >
                  Edit
                </div>
              </td>
              <td className="text-center " style={ { width: '9%' } }>
                <div
                  data-target="#userAttendanceModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedEvent(event.id);
                  } }
                >
                  Attendance
                </div>
              </td>
              <td className="text-center " style={ { width: '9%' } }>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedEvent(event.id);
                  } }
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getEventName() {
    const event = this.props.events.find(
      (obj) => obj.id === this.props.selectedEvent
    );

    return event ? event.eventName : '';
  }

  render() {
    return (
      <div className="events-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px', width: '100%' } }>
              Events
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={ this.props.eventFilter }
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={ (e) => {
                this.props.updateEventFilter(e.target.value);
              } }
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
            >
              Add Event
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={ { width: '24%' } }>
                    Name
                  </th>
                  <th className="text-center" style={ { width: '23%' } }>
                    Start Time
                  </th>
                  <th className="text-center" style={ { width: '23%' } }>
                    End Time
                  </th>
                  <th className="text-center " style={ { width: '9%' } }>
                    Edit
                  </th>
                  <th className="text-center " style={ { width: '9%' } }>
                    Attendance
                  </th>
                  <th className="text-center " style={ { width: '9%' } }>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderevents()}</tbody>
            </table>
          </div>
        </div>

        <AddEventModal />
        <UserAttendanceModal />
        <EditEventModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={ { width: '80%', margin: '0 auto' } }
            >
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => {
                    this.props.updateSelectedEvent(0);
                  } }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={ { textAlign: 'center', fontSize: '18px' } }
              >
                <strong>'{this.getEventName()}'</strong> will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.eventDeleteRequest(this.props.selectedEvent);
                    this.props.updateSelectedEvent(0);
                  } }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.props.updateSelectedEvent(0);
                  } }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.admin.admin.events,
  eventFilter: state.admin.events.eventFilter,
  selectedEvent: state.admin.events.selectedEvent,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateEventFilter,
  updateSelectedEvent,
})(Events);
