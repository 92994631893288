import React from 'react';
import './access.css';
import {
  updateAccessFilter,
  updateSelectedAccess,
} from '../../../actions/adminActions/accessActions';
import { connect } from 'react-redux';
import { access } from '../../../apis/backend';
import EditModal from './accessModals/editModal.jsx';
import AddModal from './accessModals/addModal.jsx';

class Access extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
  }

  renderAccess() {
    //renders the access table
    return (
      <>
        {this.props.access
          .filter((access) => {
            const filterString = `${ access.name } ${ access.price } ${
              Math.ceil(access.duration / (24 * 60 * 60 * 1000)) + ' day(s)'
            }`;
            return (
              filterString
                .toLowerCase()
                .indexOf(this.props.accessFilter.toLowerCase()) > -1
            );
          })
          .filter((access) => access.hidden === this.state.hidden)
          .map((access) => (
            <tr key={ this.props.access.indexOf(access) }>
              <td className="text-center" style={ { width: '30%' } }>
                {`${ access.name }`}
              </td>
              <td className="text-center " style={ { width: '20%' } }>
                {'$' + access.price.toFixed(2)}
              </td>
              <td className="text-center" style={ { width: '30%' } }>
                {Math.ceil(access.duration / (24 * 60 * 60 * 1000)) + ' day(s)'}
              </td>
              <td className="text-center " style={ { width: '10%' } }>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedAccess(access.id);
                  } }
                >
                  Edit
                </div>
              </td>

              <td className="text-center " style={ { width: '10%' } }>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={ () => {
                    this.props.updateSelectedAccess(access.id);
                  } }
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getAccessName() {
    const access = this.props.access.find(
      (obj) => obj.id === this.props.selectedAccess
    );

    return access ? access.name : '';
  }

  render() {
    return (
      <div className="access-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px', width: '100%' } }>
              User Access
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={ this.props.accessFilter }
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={ (e) => {
                this.props.updateAccessFilter(e.target.value);
              } }
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
            >
              Add Access
            </div>
            <div
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={ { marginLeft: '10px' } }
              onClick={ () => {
                this.setState({ hidden: !this.state.hidden });
              }}
            >
              Show Hidden: {this.state.hidden ? 'On' : 'Off'}
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center " style={ { width: '30%' } }>
                    Name
                  </th>
                  <th className="text-center" style={ { width: '20%' } }>
                    Price
                  </th>
                  <th className="text-center" style={ { width: '30%' } }>
                    Duration
                  </th>
                  <th className="text-center" style={ { width: '10%' } }>
                    Edit
                  </th>
                  <th className="text-center" style={ { width: '10%' } }>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderAccess()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={ { width: '80%', margin: '0 auto' } }
            >
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => {
                    this.props.updateSelectedAccess(0);
                  } }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={ { textAlign: 'center', fontSize: '18px' } }
              >
                <strong>'{this.getAccessName()}'</strong> will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={ async () => {
                    await access
                      .delete(`/${ this.props.selectedAccess }`, {
                        headers: { authorization: this.props.employeeToken },
                      })
                      .catch((response) => {
                        console.log(response);
                      });
                    this.props.updateSelectedAccess(0);
                  } }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.props.updateSelectedAccess(0);
                  } }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  access: state.admin.admin.access,
  selectedAccess: state.admin.access.selectedAccess,
  accessFilter: state.admin.access.accessFilter,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateAccessFilter,
  updateSelectedAccess,
})(Access);
