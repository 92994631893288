import React from 'react';
import classNames from 'classnames';
import './scrollView.css';

export default class ScrollView extends React.Component {
  renderOptions(options) {
    return (
      <div className={ 'scrollViewLabelContain' }>
        {options.columns.map((column) => (
          <div
            key={ options.columns.indexOf(column) }
            className="scrollViewLabel"
            style={ {
              fontSize: `${ column.size ? column.size : 16 }pt`,
              width: `calc(${ column.width }% - 10px)`,
              justifyContent: `${ column.align ? column.align : 'left' }`,
              paddingLeft: `${ column.align ? '0' : '10px' }`
            } }
          >
            {options.titles[ options.columns.indexOf(column) ]}
          </div>
        ))}
      </div>
    );
  }

  formatOptionType(entry) {
    if (this.props.formatOption) {
      return <p>{entry}</p>;
    } else {
      return entry;
    }
  }

  renderList(options) {
    return (
      <div
        className={ classNames({
          scrollViewList: true,
          scrollViewListWithLabels: true
        }) }
        style={ {
          overflow: 'scroll'
        } }
      >
        {options.entries.length === 0 ? (
          <h1>No items to display</h1>
        ) : (
          options.entries.map((entry) => (
            <div
              className={ classNames({
                scrollViewListOption: true,
                scrollViewListOptionSelected: entry.id === options.selected
              }) }
              style={{
                height: `${ options.userNotes ? '80px' : '' }`,
                whiteSpace: `${ options.userNotes ? 'normal' : '' }`
              }}
              onClick={ () => !options.userNotes ? options.selectEvent(entry.id) : null }
              key={ entry.id }
            >
              {entry.columns.map((entryColumn) => {
                const columnEntry = options.columns[ entry.columns.indexOf(entryColumn) ];
                return (<div
                  className="scrollViewListOptionCol"
                  style={ {
                    width: `calc(${ columnEntry.width }% - 10px)`,
                    height: `${ options.userNotes ? '80px' : '' }`,
                    justifyContent: `${ columnEntry.align ? columnEntry.align : 'left' }`,
                    paddingLeft: `${ columnEntry.align ? '0' : '10px' }`
                  } }
                  key={ entryColumn }
                >
                  {this.formatOptionType(entryColumn)}
                </div>
                );
              })}
            </div>
          ))
        )}
      </div>
    );
  }

  renderButtons(buttons) {
    return (
      <div className="scrollViewButtonsContain">
        {buttons.left ? (
          <div
            className="scrollViewButtonContain"
            style={ { justifyContent: 'flex-start' } }
          >
            <button
              className="scrollViewButton"
              onClick={ () => buttons.left.onClick() }
              disabled={ buttons.left.disabled }
            >
              {buttons.left.title}
            </button>
          </div>
                ) : (
                  <div className="scrollViewButtonContain" />
                )}
        {buttons.center ? (
          <div className="scrollViewButtonContain">
            <button
              className="scrollViewButton"
              onClick={ () => buttons.center.onClick() }
              disabled={ buttons.center.disabled }
            >
              {buttons.center.title}
            </button>
          </div>
                ) : (
                  <div className="scrollViewButtonContain" />
                )}
        {buttons.right ? (
          <div
            className="scrollViewButtonContain"
            style={ { justifyContent: 'flex-end' } }
          >
            <button
              className="scrollViewButton"
              onClick={ () => buttons.right.onClick() }
              disabled={ buttons.right.disabled }
            >
              {buttons.right.title}
            </button>
          </div>
                ) : (
                  <div className="scrollViewButtonContain" />
                )}
      </div>
    );
  }

  renderFilterButtons(filterOptions) {
    return (
      <div
        className="scrollViewButtonsContain"
        style={{ marginTop: '0' }}
      >
        {filterOptions.options.map((option) => (
          <div
            key={ filterOptions.options.indexOf(option) }
            className="scrollViewButtonContain"
            style={{
              width: `${ option.width }%`,
              justifyContent: this.filterButtonJustify(filterOptions, option)
            }}
          >
            <button
              className={ classNames({
                scrollViewButton: true,
                filterOptionSelected: option.value === filterOptions.selected
              }) }
              onClick={ () => option.onClick() }
            >
              {option.title}
            </button>
          </div>
        ))}
      </div>
    );
  }

  filterButtonJustify(filterOptions, option) {
    const index = filterOptions.options.indexOf(option);
    if (index === 0) {
      return 'flex-start';
    } else if (index === filterOptions.options.length - 1) {
      return 'flex-end';
    }
    return '';
  }

  renderSearchBar(searchBar) {
    return (
      <input
        value={ searchBar.filterString }
        className="scrollViewSearchBar"
        placeholder="Search..."
        onChange={ (e) => searchBar.onChange(e.target.value) }
      />
    );
  }

  renderTotal(total) {
    return (
      <div style={ { height: '10%', display: 'flex' } }>
        <h2
          style={ { margin: 'auto', fontSize: '26pt', color: 'white' } }
        >
          {total.total}
        </h2>
      </div>
    );
  }

  render() {
    const { options, filterOptions, buttons, searchBar, total } = this.props;
    const height = 100 - (options && searchBar ? 10 : 0) - (buttons ? 10 : 0) - (options && !searchBar && total ? 10 : 0) - (filterOptions ? 7 : 0);
    return (
      <>
        {options && searchBar ? this.renderSearchBar(searchBar) : null}
        {filterOptions ? this.renderFilterButtons(filterOptions) : null}
        {options && !searchBar && total ? this.renderTotal(total) : null}
        <div
          className="scrollViewList"
          style={ {
            height: 'calc(' + height + '% - ' + 36 + 'px)',
            backgroundColor: options ? 'white' : 'lightgray',
            overflow: options ? 'hidden' : 'scroll'
          } }
        >
          {options && options.titles ? (
            <>
              {this.renderOptions(options)}
              {this.renderList(options)}
            </>
          ) : (
            <>
              {this.props.children ? ( this.props.children ) : ( <h1>No items to display</h1> )}
            </>
          )}
        </div>

        {buttons ? this.renderButtons(buttons) : null}
      </>
    );
  }
}
