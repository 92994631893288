import React from 'react';
import './edit.css';
import { updateEditSelectedInventory, clearEditPage, updateInventoryComment, updateInventoryStatus } from '../../../../actions/skateparkPageActions/inventoryActions';
import { connect } from 'react-redux';
import { inventoryStatus } from '../../../../apis/backend';
import Checkmark from '../../../../images/checkmark.png';
import Xmark from '../../../../images/xmark.png';
import Warning from '../../../../images/warning.png';
import ScrollView from '../../../utils/scrollView/scrollView';
import Page from '../../../utils/page/page';
import NotFound from '../../../404/404';

class Edit extends React.Component {
  componentDidMount() {
    const id = parseInt(this.props.match.params.id);
    const inventory = this.props.inventory.find(obj => obj.id === id);
    this.props.updateEditSelectedInventory(inventory);
    this.props.updateInventoryStatus(inventory.status);
    this.setInitialItemComment(inventory);
  }

  componentWillUnmount() {
    this.props.clearEditPage();
  }

  setInitialItemComment(item) {
    const comment = (!item || !item.comments) ? '' : item.comments;
    this.props.updateInventoryComment(comment);
  }

  getInitialItemComment(inventory) {
    const item = this.props.selectedInventoryEdit;
    const comment = (!item || !item.comments) ? '' : item.comments;
    return comment;
  }

  async handleInventoryItemUpdate() {
    // TODO: change item info in database
    await inventoryStatus
            .post(
                '/',
                { inventoryId: this.props.selectedInventoryEdit.id, status: this.props.inventoryStatus, comments: this.props.inventoryComment },
                { headers: { authorization: this.props.employeeToken } }
            )
            .then(res => this.props.history.push('/skatepark/inventory'))
            .catch(err => console.log(err));
  }

  renderInventoryButtons() {
    const status = this.props.selectedInventoryEdit ? true : false;
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/skatepark/inventory')
      },
      right: {
        title: 'Update',
        onClick: () => this.handleInventoryItemUpdate(),
        disabled: (status && (this.props.selectedInventoryEdit.status === this.props.inventoryStatus) && (this.props.inventoryComment === this.getInitialItemComment()))
      }
    };
  }

  render() {
    if (!this.props.inventory.map(obj => obj.id).includes(parseInt(this.props.match.params.id))) {
      return <NotFound/>;
    }

    const currentStatus = this.props.inventoryStatus;

    return (
      <Page>
        <ScrollView
          buttons={ this.renderInventoryButtons() }
        >
          {/* Item Information */}
          <div className="item-info-container">
            <div className="item-info">
              Type: {this.props.selectedInventoryEdit ? this.props.selectedInventoryEdit.type : ''}
            </div>
            <div className="item-info">
              Number: {this.props.selectedInventoryEdit ? this.props.selectedInventoryEdit.itemNumber : ''}
            </div>
          </div>

          {/* Status Toggles */}
          <div className="status-container">
            <div className="category-label">
              Item Status
            </div>
            <div className="status-box">
              <button className={ currentStatus === 'ready' ? 'selected' : '' }
                onClick={ () => this.props.updateInventoryStatus('ready') }
              >
                <div className="button-text top"><strong>Ready</strong></div>
                <img style={ { maxWidth: '47%' } } src={ Checkmark } alt="Ready" />
                <div className="button-text bottom">Item is ready to go</div>
              </button>
              <button className={ currentStatus === 'warning' ? 'selected middle-button' : 'middle-button' }
                onClick={ () => this.props.updateInventoryStatus('warning') }
              >
                <div className="button-text top"><strong>Warning</strong></div>
                <img style={ { maxWidth: '47%' } } src={ Warning } alt="Warning" />
                <div className="button-text bottom">Item may need fixing</div>
              </button>
              <button className={ currentStatus === 'broken' ? 'selected' : '' }
                onClick={ () => this.props.updateInventoryStatus('broken') }
              >
                <div className="button-text top"><strong>Broken</strong></div>
                <img style={ { maxWidth: '47%' } } src={ Xmark } alt="Broken" />
                <div className="button-text bottom">Item needs replacement</div>
              </button>
            </div>
          </div>

          {/* Item Comments */}
          <div className="comment-container">
            <div className="category-label">
              Comments
            </div>
            <textarea
              className="comment-box"
              placeholder="Additional Comments..."
              value={ this.props.inventoryComment }
              onChange={ (e) => this.props.updateInventoryComment(e.target.value) }
            />
          </div>
        </ScrollView>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  inventory: state.skatepark.skatepark.inventory,
  selectedInventoryEdit: state.skatepark.inventory.selectedInventoryEdit,
  loaded: state.skatepark.skatepark.loaded,
  inventoryComment: state.skatepark.inventory.inventoryComment,
  inventoryStatus: state.skatepark.inventory.inventoryStatus,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateEditSelectedInventory, clearEditPage, updateInventoryComment, updateInventoryStatus
})(Edit);
