import React, { Component } from 'react';
import '../../Modal.css';
import { systemAuth } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedSystemUser } from '../../../../actions/adminActions/systemUserActions';

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  async systemUserUpdateRequest() {
    await systemAuth
      .patch(`/${ this.props.selectedSystemUser }`, {
        password: this.state.password,
      }, { headers: { authorization: this.props.employeeToken } })
      .catch((response) => {
        this.setState({
          password: '',
        });
        console.log(response);
      });
  }

  render() {
    return (
      <div
        id="passwordModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reset Password</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ () => this.props.updateSelectedSystemUser(0) }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    New Password
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.password }
                  placeholder="New Password"
                  onChange={ (e) => this.setState({ password: e.target.value }) }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={ () => {
                  this.props.updateSelectedSystemUser(0);
                  this.setState({ password: '' });
                } }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.systemUserUpdateRequest();
                  this.props.updateSelectedSystemUser(0);
                  this.setState({ password: '' });
                } }
                disabled={ !(this.state.password !== '') }
              >
                Change password
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUsers: state.admin.admin.systemUsers,
  selectedSystemUser: state.admin.systemUsers.selectedSystemUser,
  systemLocations: state.admin.admin.systemLocations,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedSystemUser })(
  ResetPasswordModal
);
