export const updateSkateparkCheckinModalName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_CHECKIN_MODAL_NAME',
    payload: name,
  });
};

export const updateSkateparkCheckinModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_CHECKIN_MODAL_USER_ID',
    payload: getState().skatepark.checkin.selected === id ? 0 : id,
  });
};

export const updateSkateparkCheckinModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_CHECKIN_MODAL_ACTIVE',
    payload: value,
  });
};

export const clearSkateparkCheckinModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SKATEPARK_CHECKIN_MODAL',
  });
};

export const updateSkateparkNoteModalNote = (note) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_NOTE_MODAL_NOTE',
    payload: note,
  });
};

export const updateSkateparkNoteModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_NOTE_MODAL_USER_ID',
    payload: getState().skatepark.checkin.selected === id ? 0 : id,
  });
};

export const updateSkateparkNoteModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SKATEPARK_NOTE_MODAL_ACTIVE',
    payload: value,
  });
};

export const clearSkateparkNoteModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SKATEPARK_NOTE_MODAL',
  });
};
