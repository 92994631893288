import React from 'react';
import './mealSwipes.css';
import { connect } from 'react-redux';
import {
  updateAllowanceFilter,
  updateSelectedAllowance,
} from '../../../actions/adminActions/mealSwipesAllowanceActions';
import { mealSwipeAllowance } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './mealSwipesModals/editModal.jsx';
// import AddModal from './mealSwipesModals/addModal.jsx';

class MealSwipesAllowance extends React.Component {
  async allowanceDeleteRequest(id) {
    await mealSwipeAllowance
      .delete(`/${ id }`, {
        headers: { authorization: this.props.employeeToken },
      })
      .catch((response) => {
        console.log(response);
      });
  }

  renderAllowance() {
    //renders the users table
    return (
      <>
        {this.props.mealSwipesAllowance.map((allowance) => (
          <tr key={ this.props.mealSwipesAllowance.indexOf(allowance) }>
            <td className="text-center" style={ { width: '33.33%' } }>
              {allowance.daily}
            </td>
            <td className="text-center" style={ { width: '33.33%' } }>
              {allowance.weekly}
            </td>
            <td className="text-center " style={ { width: '33.33%' } }>
              <div
                data-target="#exampleModal"
                className="btn btn-sm btn-dark"
                data-toggle="modal"
                onClick={ () => {
                  this.props.updateSelectedAllowance(allowance.id);
                } }
              >
                Edit
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }

  render() {
    return (
      <div className="users-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-5"
            style={ { fontSize: '36px', color: '#fff' } }
          >
            <div style={ { margin: 'auto', height: '48px' } }>
              Meal Swipe Allowance
            </div>
            {/* <input
              value={this.props.mealSwipeAllowance}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateAllowanceFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={{marginLeft: '10px'}}
            >
              Add User
            </div> */}
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={ { width: '33.33%' } }>
                    Daily Allowance
                  </th>
                  <th className="text-center" style={ { width: '33.33%' } }>
                    Weekly Allowance
                  </th>
                  <th className="text-center " style={ { width: '33.33%' } }>
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderAllowance()}</tbody>
            </table>
          </div>
        </div>

        {/* <AddModal /> */}

        <EditModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={ { width: '80%', margin: '0 auto' } }
            >
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={ () => {
                    this.props.updateSelectedAllowance(0);
                  } }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={ { textAlign: 'center', fontSize: '18px' } }
              >
                This user's meal swipe allowance will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.allowanceDeleteRequest(this.props.selectedUser);
                    this.props.updateSelectedAllowance(0);
                  } }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={ () => {
                    this.props.updateSelectedAllowance(0);
                  } }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mealSwipesAllowance: state.admin.admin.mealSwipesAllowance,
  mealSwipesAllowanceFilter: state.admin.allowance.mealSwipesAllowanceFilter,
  selectedMealSwipesAllowance:
    state.admin.allowance.selectedMealSwipesAllowance,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, {
  updateAllowanceFilter,
  updateSelectedAllowance,
})(MealSwipesAllowance);
