import React, { Component } from 'react';
import './../../Modal.css';
import { mealSwipeAllowance } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedAllowance } from '../../../../actions/adminActions/mealSwipesAllowanceActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daily: '',
      weekly: '',
    };
  }

  async allowanceUpdateRequest() {
    // console.log(this.state);
    await mealSwipeAllowance
      .patch(
        `/${ this.props.selectedMealSwipesAllowance }`,
        {
          daily: this.state.daily,
          weekly: this.state.weekly,
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => {
        console.log(response);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedMealSwipesAllowance !==
        this.props.selectedMealSwipesAllowance &&
      this.props.selectedMealSwipesAllowance !== 0
    ) {
      const allowance = this.props.mealSwipesAllowance.find(
        (obj) => obj.id === this.props.selectedMealSwipesAllowance
      );
      // console.log(allowance);
      if (allowance) {
        this.setState({
          daily: allowance.daily,
          weekly: allowance.weekly,
        });
      }
    }
  }

  render() {
    return (
      <div
        id="exampleModal"
        className="modal fade"
        style={ { overflow: 'scroll' } }
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Meal Swipe Allowance</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ () => this.props.updateSelectedAllowance(0) }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Daily Allowance
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.daily }
                  placeholder="Daily Allowance"
                  onChange={ (e) => this.setState({ daily: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Weekly Allowance
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.weekly }
                  placeholder="Weekly Allowance"
                  onChange={ (e) => this.setState({ weekly: e.target.value }) }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={ () => this.props.updateSelectedAllowance(0) }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.allowanceUpdateRequest();
                  this.props.updateSelectedAllowance(0);
                } }
                disabled={
                  !(
                    this.state.daily !== '' &&
                    this.state.weekly !== '' &&
                    this.state.daily >= 0 &&
                    this.state.weekly >= 0
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mealSwipesAllowance: state.admin.admin.mealSwipesAllowance,
  selectedMealSwipesAllowance:
    state.admin.allowance.selectedMealSwipesAllowance,
  employeeToken: state.auth.employeeToken,
});

export default connect(mapStateToProps, { updateSelectedAllowance })(EditModal);
