import React, { Component } from 'react';
import '../../Modal.css';
import { systemAuth } from '../../../../apis/backend';
import { connect } from 'react-redux';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      location: '',
      password: '',
    };
  }

  async systemUserRequest() {
    try {
      await systemAuth
        .post(
          '/signup',
          {
            username: this.state.username,
            location: parseInt(this.state.location),
            password: this.state.password,
          },
          { headers: { systemauthorization: this.props.systemToken } }
        )
        .then(() => {
          this.setState({
            username: '',
            location: '',
            password: '',
          });
        })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={ { overflow: 'scroll' } }>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add System Login</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Username
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.username }
                  placeholder="Username"
                  onChange={ (e) => this.setState({ username: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Password
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.password }
                  placeholder="Password"
                  onChange={ (e) => this.setState({ password: e.target.value }) }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Location
                  </span>
                </div>
                <select
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={ this.state.location }
                  onChange={ (e) => {
                    this.setState({ location: e.target.value });
                  } }
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {this.props.systemLocations.map((location) => (
                    <option key={ location.id } value={ location.id }>
                      {location.location}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={ () => {
                  this.systemUserRequest();
                } }
                disabled={
                  !(
                    this.state.username !== '' &&
                    this.state.password !== '' &&
                    this.state.location !== ''
                  )
                }
              >
                Create System Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUsers: state.admin.admin.systemUsers,
  selectedSystemUser: state.admin.systemUsers.selectedSystemUser,
  systemLocations: state.admin.admin.systemLocations,
  systemToken: state.auth.systemToken,
});

export default connect(mapStateToProps, {})(AddModal);
