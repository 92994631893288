export const updateEmployeeFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_EMPLOYEE_PAGE_FILTER_STRING',
    payload: filterString,
  });
};

export const updateSelectedEmployee = (employee) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_EMPLOYEE_PAGE_SELECTED_EMPLOYEE',
    payload: employee,
  });
};
