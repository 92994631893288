import React from 'react';
import './buttonColumn.css';

export default class ButtonColumn extends React.Component {
  renderLogo() {
    if (this.props.logo) {
      return (
        <div className='buttonColumnImageContain'>
          <img
            src={ this.props.logo }
            alt='logo'
            style={ { maxWidth: '85%' } }
          />
        </div>
      );
    }
  }

  renderButtonHeight() {
    if (this.props.logo) {
      return 85 / this.props.buttons.length;
    }
    return 100 / this.props.buttons.length;
  }

  renderButtons() {
    if (this.props.buttons) {
      return this.props.buttons.map((button) => (
        <button
          className='buttonColumnButton'
          onClick={ () => button.onClick() }
          key={ button.text }
          style={ {
            height: `calc(${ this.renderButtonHeight() }% - 10px)`,
          } }
        >
          {button.text}
        </button>
      ));
    }
  }

  render() {
    return (
      <div className='buttonColumnContain'>
        {this.renderLogo()}
        {this.renderButtons()}
      </div>
    );
  }
}
