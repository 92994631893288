import React from 'react';
import './home.css';
import FeedItem from '../../utils/feeditem/feedItem.jsx';
import { connect } from 'react-redux';
import Logo from '../../../images/skatepark.png';
import FeedView from '../../utils/feedView/feedView.jsx';
import Page from '../../utils/page/page';
import ButtonColumn from '../../utils/buttonColumn/buttonColumn';
import { lock } from '../../../actions/authActions';
import {
  changeActiveTab,
  clearUnreadTally,
} from '../../../actions/skateparkPageActions/skateparkActions.js';
import {
  clearSkateparkCheckinModal,
} from '../../../actions/skateparkPageActions/skateparkPopupActions.js';
import { updateShowButtons } from '../../../actions/skateparkPageActions/homeActions.js';
import CheckinAfterPurchase from '../../utils/popups/checkinAfterPurchase.jsx';

class HomePage extends React.Component {
  clearModalData() {
    this.props.clearSkateparkCheckinModal();
  }

  render() {
    let feedItems;
    let newsItems;

    // Dynamically displays feed items
    if (this.props.feedItems.length === 0) {
      feedItems = <h1>No items to display</h1>;
    } else {
      feedItems = this.props.feedItems.map((log) => (
        <FeedItem
          title={ log.user }
          status={ log.status }
          messages={ log.messages }
          userId={ log.userId }
          id={ log.id }
          key={ log.id }
          history={ this.props.history }
          type='skatepark'
        />
      ));
    }

    // Dynamically displays news items
    if (this.props.newsItems.length === 0) {
      newsItems = <h1>No items to display</h1>;
    } else {
      newsItems = this.props.newsItems.map((news) => (
        <FeedItem
          title={ news.title }
          status={ news.read ? 'read' : 'unread' }
          messages={ [ news.message ] }
          id={ news.id }
          key={ news.id }
          history={ this.props.history }
          type='news'
        />
      ));
    }

    const menuButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/skatepark/register'),
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/skatepark/rfid'),
      },
      {
        text: 'Manual Check-in',
        onClick: () => this.props.history.push('/skatepark/checkin'),
      },
      {
        text: 'User Notes',
        onClick: () => this.props.history.push('/skatepark/notescheckin'),
      },
      {
        text: 'Purchase',
        onClick: () =>
          this.props.history.push('/skatepark/purchasecheckin'),
      },
      {
        text: 'Rentals',
        onClick: () =>
          this.props.history.push('/skatepark/rentalscheckin'),
      },
      {
        text: 'Inventory',
        onClick: () => this.props.history.push('/skatepark/inventory'),
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock(),
      },
    ];

    return (
      <Page>
        <div className='skateparkPageColumn'>
          <ButtonColumn logo={ Logo } buttons={ menuButtons } />
        </div>
        <div className='skateparkPageColumn'>
          <FeedView
            activity={ feedItems }
            updates={ newsItems }
            activeTab={ this.props.activeTab }
            tabClick={ (index) => {
              this.props.changeActiveTab(index);
              this.props.clearUnreadTally();
              this.props.updateShowButtons(0);
            } }
            tally={ {
              newsUnreadTally: this.props.newsUnreadTally,
              activityUnreadTally: this.props.activityUnreadTally,
            } }
          ></FeedView>
        </div>
        <CheckinAfterPurchase
          name={ this.props.name }
          id={ this.props.id }
          visible={ this.props.visible }
          clearModal={ this.clearModalData.bind(this) }
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  feedItems: state.skatepark.skatepark.feedItems,
  newsItems: state.skatepark.skatepark.newsItems,
  activeTab: state.skatepark.skatepark.activeTab,
  newsUnreadTally: state.skatepark.skatepark.newsUnreadTally,
  activityUnreadTally: state.skatepark.skatepark.activityUnreadTally,
  name: state.skatepark.skateparkPopup.userName,
  id: state.skatepark.skateparkPopup.checkinUserId,
  visible: state.skatepark.skateparkPopup.checkinVisible,
});

export default connect(mapStateToProps, {
  changeActiveTab,
  clearUnreadTally,
  lock,
  updateShowButtons,
  clearSkateparkCheckinModal,
})(HomePage);
