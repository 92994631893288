const INITIAL_STATE = {
  selectedInventory: 0,
  selectedInventoryEdit: undefined,
  inventoryFilter: '',
  inventoryComment: '',
  inventoryStatus: 'broken'
};

export const collectiveInventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COLLECTIVE_INVENTORY_PAGE_SELECTED_INVENTORY':
      return {
        ...state,
        selectedInventory: action.payload
      };
    case 'COLLECTIVE_INVENTORY_PAGE_UPDATE_FILTER_STRING':
      return {
        ...state,
        inventoryFilter: action.payload
      };
    case 'CLEAR_COLLECTIVE_INVENTORY_PAGE':
      return INITIAL_STATE;
    case 'UPDATE_COLLECTIVE_INVENTORY_EDIT_PAGE_SELECTED_INVENTORY':
      return {
        ...state,
        selectedInventoryEdit: action.payload
      };
    case 'UPDATE_COLLECTIVE_INVENTORY_EDIT_PAGE_COMMENT':
      return {
        ...state,
        inventoryComment: action.payload
      };
    case 'UPDATE_COLLECTIVE_INVENTORY_EDIT_PAGE_STATUS':
      return {
        ...state,
        inventoryStatus: action.payload
      };
    case 'CLEAR_COLLECTIVE_INVENTORY_EDIT_PAGE':
      return INITIAL_STATE;
    default:
      return state;
  }
};
