const INITIAL_STATE = {
  connected: false,
  io: null
};

export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIALIZE_WEB_SOCKETS':
      return {
        ...state,
        io: action.payload.socket,
        connected: true
      };
    default:
      return state;
  }
};
